/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import classNames from "classnames";
import { Sidebar, TextInput } from "flowbite-react";
import type { FC } from "react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { CgGym } from "react-icons/cg";
import { GiWeightLiftingUp } from "react-icons/gi";
import {
  HiChartBar,
  HiChartPie,
  HiPaperClip,
  HiSearch,
  HiUserGroup,
  HiUsers,
  HiVideoCamera,
} from "react-icons/hi";

import { useSidebarContext } from "../context/SidebarContext";
import isSmallScreen from "../helpers/is-small-screen";

const ExampleSidebar: FC = function () {
  const { isOpenOnSmallScreens: isSidebarOpenOnSmallScreens } =
    useSidebarContext();

  const [currentPage, setCurrentPage] = useState("");

  useEffect(() => {
    const newPage = window.location.pathname;

    setCurrentPage(newPage);
  }, [setCurrentPage]);

  return (
    <div
      className={classNames("lg:!block", {
        hidden: !isSidebarOpenOnSmallScreens,
      })}
    >
      <Sidebar
        aria-label="Sidebar with multi-level dropdown example"
        collapsed={isSidebarOpenOnSmallScreens && !isSmallScreen()}
      >
        <div className="flex h-full flex-col justify-between py-2">
          <div>
            <form className="pb-3 md:hidden">
              <TextInput
                icon={HiSearch}
                type="search"
                placeholder="Search"
                required
                size={32}
              />
            </form>
            <Sidebar.Items>
              <Sidebar.ItemGroup>
                <Link to="/">
                  <Sidebar.Item
                    icon={HiChartPie}
                    className={
                      "/" === currentPage ? "bg-gray-100 dark:bg-gray-700" : ""
                    }
                  >
                    Dashboard
                  </Sidebar.Item>
                </Link>

                <Link to="/programs">
                  <Sidebar.Item
                    icon={HiPaperClip}
                    className={
                      "/programs" === currentPage
                        ? "bg-gray-100 dark:bg-gray-700"
                        : ""
                    }
                  >
                    Programs
                  </Sidebar.Item>
                </Link>

                <Link to="/workouts">
                  <Sidebar.Item
                    icon={CgGym}
                    className={
                      "/workouts" === currentPage
                        ? "bg-gray-100 dark:bg-gray-700"
                        : ""
                    }
                  >
                    Workouts
                  </Sidebar.Item>
                </Link>

                <Link to="/exercises">
                  <Sidebar.Item
                    icon={GiWeightLiftingUp}
                    className={
                      "/exercises" === currentPage
                        ? "bg-gray-100 dark:bg-gray-700"
                        : ""
                    }
                  >
                    Exercises
                  </Sidebar.Item>
                </Link>

                <Link to="/videos">
                  <Sidebar.Item
                    icon={HiVideoCamera}
                    className={
                      "/videos" === currentPage
                        ? "bg-gray-100 dark:bg-gray-700"
                        : ""
                    }
                  >
                    Videos
                  </Sidebar.Item>
                </Link>

                <Link to="/trainers">
                  <Sidebar.Item
                    icon={HiUserGroup}
                    className={
                      "/trainers" === currentPage
                        ? "bg-gray-100 dark:bg-gray-700"
                        : ""
                    }
                  >
                    Athletes
                  </Sidebar.Item>
                </Link>

                <Link to="/users/list">
                  <Sidebar.Item
                    icon={HiUsers}
                    className={
                      "/users/list" === currentPage
                        ? "bg-gray-100 dark:bg-gray-700"
                        : ""
                    }
                  >
                    Users
                  </Sidebar.Item>
                </Link>

                <Link to="/spotlight">
                  <Sidebar.Item
                    icon={HiChartBar}
                    className={
                      "/spotlight" === currentPage
                        ? "bg-gray-100 dark:bg-gray-700"
                        : ""
                    }
                  >
                    Spotlight
                  </Sidebar.Item>
                </Link>
              </Sidebar.ItemGroup>
            </Sidebar.Items>
          </div>
        </div>
      </Sidebar>
    </div>
  );
};

export default ExampleSidebar;
