import { combineReducers } from "redux";
import authReducer from "./auth";
import exercisesReducer from "./exercises";
import programsReducer from "./programs";
import trainersReducer from "./trainers";
import usersReducer from "./users";
import videosReducer from "./videos";
import workoutsReducer from "./workouts";

const reducers = combineReducers({
  auth: authReducer,
  users: usersReducer,
  workouts: workoutsReducer,
  programs: programsReducer,
  videos: videosReducer,
  trainers: trainersReducer,
  exercises: exercisesReducer,
});

export default reducers;
export type RootState = ReturnType<typeof reducers>;
