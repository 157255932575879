/* eslint-disable tailwindcss/no-custom-classname */
import { useEffect, useRef, useState } from "react";
import { Button, Modal } from "flowbite-react";
import { HiPencil } from "react-icons/hi";
import { toast } from "react-toastify";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { MdDragIndicator } from "react-icons/md";
import { FaCircleInfo } from "react-icons/fa6";
import { updateProgramsOrder } from "../../services/programs";

const EditProgramLibrary = function (props: any) {
  const [isOpen, setOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const rootRef = useRef<HTMLDivElement>(null);
  const [programs, setPrograms]: any = useState([]);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const simplified = programs.reduce((acc: any, program: any, index: any) => {
      const programEntry = {
        id: program.id,
        order: index,
      };
      acc.push(programEntry);
      return acc;
    }, []);
    await updateProgramsOrder({
      programs: simplified,
    });
    props.getPrograms();
    setIsSubmitting(false);
    setOpen(false);
    toast.success("Program Library Saved Successfully!");
  };

  useEffect(() => {
    if (props?.programs?.data?.length) {
      const filtered = props.programs.data.filter(
        (program: any) => program.published,
      );
      const sorted = filtered.sort(
        (a: { order: number }, b: { order: number }) => a.order - b.order,
      );
      setPrograms(sorted);
    }
  }, [isOpen, props]);

  const assignOrder = (items: any) =>
    items.map((video: any, i: any) => ({ ...video, order: i }));

  const onDragEnd = (result: any) => {
    if (!result.destination) return;
    console.log("onDragEnd ", onDragEnd);

    const items = Array.from(programs);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setPrograms(assignOrder(items));
  };

  return (
    <>
      <div ref={rootRef}>
        <Button color="primary" onClick={() => setOpen(true)}>
          <div className="flex items-center gap-x-3">
            <HiPencil className="text-xl" />
            Edit Program Library
          </div>
        </Button>
        <Modal
          onClose={() => setOpen(false)}
          show={isOpen}
          size="xl"
          position="top-center"
          root={rootRef.current ?? undefined}
        >
          <Modal.Header className="border-b border-gray-200 !p-6 dark:border-gray-700">
            <strong>Edit Program Library</strong>
          </Modal.Header>
          <Modal.Body>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId={`program-library-edit`}>
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {programs.map((program: any, index: any) => (
                      <Draggable
                        key={program.id}
                        draggableId={String(program.id)}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className="mt-3 flex flex-row items-center gap-1 rounded-lg border border-gray-400 bg-gray-50 px-4 py-3 text-base text-gray-800 dark:bg-gray-800 dark:text-gray-300"
                          >
                            <MdDragIndicator className="inline text-xl text-gray-700" />{" "}
                            <span className="ml-2">{program.title}</span>
                            <div className="ml-auto flex flex-row items-center"></div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <div className="-mb-4 mt-4 flex flex-row items-center text-sm text-gray-700">
              <FaCircleInfo className="mr-2 inline" />
              <span>
                {"The program has to be published to be asigned an order."}
              </span>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              color="primary"
              onClick={handleSubmit}
              disabled={isSubmitting}
            >
              {isSubmitting ? "Saving..." : "Save"}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default EditProgramLibrary;
