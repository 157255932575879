/* eslint-disable tailwindcss/classnames-order */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import type { FC } from "react";
import { Avatar, Dropdown, Navbar } from "flowbite-react";
import {
  HiCog,
  HiLogout,
  HiMenuAlt1,
  HiSearch,
  HiViewGrid,
  HiX,
} from "react-icons/hi";
import { useSidebarContext } from "../context/SidebarContext";
import isSmallScreen from "../helpers/is-small-screen";
import cookie from "js-cookie";
import { useNavigate } from "react-router-dom";
import { fetchCurrentUser } from "../redux/actionCreators/auth";
import { currentUser } from "../services/user";
import { useDispatch } from "react-redux";
import AppLogo from "../assets/images/logo.png";

const ThemeNavbar: FC = function () {
  const { isOpenOnSmallScreens, isPageWithSidebar, setOpenOnSmallScreens } =
    useSidebarContext();

  const [user, setUser]: any = useState({});

  const dispatch: any = useDispatch();

  const navigate = useNavigate();

  const token = cookie.get("token");

  useEffect(() => {
    if (!token) {
      navigate("/login");
      return;
    }
    const init = async () => {
      await getCurrentUser();
    };
    init();
  }, [navigate, token]);

  useEffect(() => {
    if (user && user.id) {
      dispatch(fetchCurrentUser());
    }
  }, [dispatch, user]);

  const getCurrentUser = async () => {
    let user: any = await currentUser();
    // updating current user in cookies

    user = user.data;

    console.log("[DEBUG] user", user);

    cookie.set("name", user.user_name, { expires: 400 });
    cookie.set("email", user.user_email, { expires: 400 });
    cookie.set("user_profile_image", user.profile_image, { expires: 400 });

    setUser({
      ...user,
    });
  };

  return (
    <Navbar fluid>
      <div className="w-full p-3 lg:px-5 lg:pl-3">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            {isPageWithSidebar && (
              <button
                onClick={() => setOpenOnSmallScreens(!isOpenOnSmallScreens)}
                className="mr-3 cursor-pointer rounded p-2 text-gray-600 hover:bg-gray-100 hover:text-gray-900 lg:inline dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              >
                <span className="sr-only">Toggle sidebar</span>
                {isOpenOnSmallScreens && isSmallScreen() ? (
                  <HiX className="size-6" />
                ) : (
                  <HiMenuAlt1 className="size-6" />
                )}
              </button>
            )}
            <Navbar.Brand href="/">
              <img alt="" src={AppLogo} className="mr-3 h-6 sm:h-8" />
              <span className="self-center whitespace-nowrap text-2xl font-semibold dark:text-white"></span>
            </Navbar.Brand>
          </div>
          <div className="flex items-center lg:gap-3">
            <div className="flex items-center">
              <button
                onClick={() => setOpenOnSmallScreens(!isOpenOnSmallScreens)}
                className="cursor-pointer rounded p-2 text-gray-600 hover:bg-gray-100 hover:text-gray-900 focus:bg-gray-100 focus:ring-2 focus:ring-gray-100 lg:hidden dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white dark:focus:bg-gray-700 dark:focus:ring-gray-700"
              >
                <span className="sr-only">Search</span>
                <HiSearch className="size-6" />
              </button>
            </div>
            <div className="hidden lg:block">
              <UserDropdown />
            </div>
          </div>
        </div>
      </div>
    </Navbar>
  );
};

const UserDropdown: FC = function () {
  const navigate = useNavigate();

  const signOut = () => {
    cookie.remove("token");
    cookie.remove("admin");
    navigate("/");
    return;
  };

  return (
    <Dropdown
      arrowIcon={false}
      inline
      label={
        <span>
          <span className="sr-only">User menu</span>
          <Avatar
            alt=""
            img={cookie.get("user_profile_image")}
            rounded
            size="sm"
          />
        </span>
      }
    >
      <Dropdown.Header className="border-b border-gray-200">
        <span className="block text-sm font-bold"> {cookie.get("name")}</span>
        <span className="block truncate text-sm">{cookie.get("email")}</span>
      </Dropdown.Header>
      <Dropdown.Item icon={HiViewGrid} onClick={() => navigate("/")}>
        Dashboard
      </Dropdown.Item>
      <Dropdown.Item className="text-gray-400 cursor-not-allowed" icon={HiCog}>
        Settings
      </Dropdown.Item>
      <Dropdown.Divider className="bg-gray-200" />
      <Dropdown.Item onClick={signOut} icon={HiLogout}>
        Sign out
      </Dropdown.Item>
    </Dropdown>
  );
};

export default ThemeNavbar;
