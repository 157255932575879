import { ActionType } from "../actionTypes/index";

const initialState = {
  users: [],
  event: null,
};

const usersReducer = (state = initialState, action: any): any => {
  console.log("=== Reducer Users ===");
  console.log(action);
  switch (action.type) {
    case ActionType.GET_ALL_USERS:
      return {
        ...state,
        users: action.payload.data.results,
        event: action.type,
      };
    case ActionType.GET_ALL_USERS_FAILED:
      return {
        ...state,
        event: action.type,
      };
    default:
      return state;
  }
};

export default usersReducer;
