import type {
  ExerciseResponse,
  ExerciseTypeResponse,
  MusclesResponse,
} from "types/apiResponses";
import config from "../config/config";
import axios from "../util/axios";

export async function getExerciseDetails(id: any): Promise<ExerciseResponse> {
  return (await axios.get(`${config.defaults.api_url}/exercises/${id}`)).data;
}

export async function updateExercise(params: any, id: any) {
  return axios.post(`${config.defaults.api_url}/exercises/${id}`, params);
}

export async function deleteExercise(id: any) {
  return axios.delete(`${config.defaults.api_url}/exercises/delete/${id}`);
}

export async function createExercise(params: any) {
  return axios.post(`${config.defaults.api_url}/exercises/create`, params);
}

export async function getAllExerciseTypes(): Promise<ExerciseTypeResponse> {
  const response = await axios.get(`${config.defaults.api_url}/exercises/type`);
  return response.data;
}

export async function getAllMuscles(): Promise<MusclesResponse> {
  const response = await axios.get(
    `${config.defaults.api_url}/exercises/muscles`,
  );
  return response.data;
}
