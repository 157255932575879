export const isLocalhost =
  window.location.hostname === "localhost" ||
  window.location.hostname === "127.0.0.1"
    ? true
    : false;

export const isDev =
  window.location.hostname === "manage-dev.sweatsonic.com" ? true : false;

const config = {
  defaults: {
    namespace: "Application",
    api_url: isLocalhost
      ? "http://localhost:4000"
      : isDev
        ? "https://api-dev.sweatsonic.com"
        : "https://api.sweatsonic.com",
  },
};

export const enableSentryLogs = false;

export default config;
