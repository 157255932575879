import { Button, Label, Modal, TextInput } from "flowbite-react";
import { useMemo, useRef, useState } from "react";
import { toast } from "react-toastify";
import { createTrainer } from "../../services/trainers";
import ImageUploadSection from "./ImageUploadSection";

const AddTrainerModal = ({ isOpen, setOpen, getTrainers }: any) => {
  const rootRef = useRef<HTMLDivElement>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [trainer, setTrainer]: any = useState({
    title: null,
    username: null,
    description: null,
    slug: null,
    gender: "male",
    squad: "false",
    featured: "false",
    user: null,
    social_ig: "",
    social_fb: "",
    social_tw: "",
    social_tt: "",
    social_yt: "",
  });

  const handleSubmit = async () => {
    setIsSubmitting(true);

    const newTrainer = await createTrainer(trainer);

    if (newTrainer.data.error) {
      toast.error(newTrainer.data.message);
    } else {
      getTrainers();
      setOpen(false);
      toast.success("New video created successfully!");
    }

    setIsSubmitting(false);
  };

  const handleChange = (name: string, value: any) => {
    const data: any = {
      ...trainer,
      [name]: value,
    };
    console.log("[DEBUG] Trainer data: ", data);
    setTrainer(data);
  };

  const isSubmitDisabled = useMemo(() => {
    const { title, username, description, slug } = trainer;
    return !(title && username && description && slug && !isSubmitting);
  }, [trainer, isSubmitting]);

  return (
    <div ref={rootRef}>
      <Modal
        onClose={() => {
          console.log("close");
          setOpen(false);
        }}
        show={isOpen}
        size="3xl"
        position="top-center"
        root={rootRef.current ?? undefined}
      >
        <Modal.Header className="border-b border-gray-200 !p-6 dark:border-gray-700">
          <strong>Add New Athlete</strong>
        </Modal.Header>
        <Modal.Body>
          <div className="grid grid-cols-1 gap-4 overflow-hidden sm:grid-cols-2">
            <div>
              <Label>Title</Label>
              <div className="mt-1">
                <TextInput
                  id="title"
                  name="title"
                  placeholder="Title"
                  onChange={({ target: { name, value } }) =>
                    handleChange(name, value)
                  }
                />
              </div>
            </div>
            <div>
              <Label>Username</Label>
              <div className="mt-1">
                <TextInput
                  id="username"
                  name="username"
                  placeholder="Username"
                  onChange={({ target: { name, value } }) =>
                    handleChange(name, value)
                  }
                />
              </div>
            </div>
            <div>
              <Label>Description</Label>
              <div className="mt-1">
                <TextInput
                  id="description"
                  name="description"
                  placeholder="Description"
                  onChange={({ target: { name, value } }) =>
                    handleChange(name, value)
                  }
                />
              </div>
            </div>
            <div>
              <Label>Slug</Label>
              <div className="mt-1">
                <TextInput
                  id="slug"
                  name="slug"
                  placeholder="Slug"
                  onChange={({ target: { name, value } }) =>
                    handleChange(name, value)
                  }
                />
              </div>
            </div>
            <div>
              <Label>Gender</Label>
              <div className="mt-1">
                <select
                  onChange={({ target: { name, value } }) =>
                    handleChange(name, value)
                  }
                  name="gender"
                  className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                >
                  <option disabled>Gender</option>
                  <option value={"male"}>{"Male"}</option>
                  <option value={"female"}>{"Female"}</option>
                </select>
              </div>
            </div>
            <div>
              <Label>Instagram (Optional)</Label>
              <div className="mt-1">
                <TextInput
                  id="instagram"
                  name="social_ig"
                  placeholder="Instagram"
                  onChange={({ target: { name, value } }) =>
                    handleChange(name, value)
                  }
                />
              </div>
            </div>
            <div>
              <Label>TikTok (Optional)</Label>
              <div className="mt-1">
                <TextInput
                  id="tiktok"
                  name="social_tt"
                  placeholder="Tiktok"
                  onChange={({ target: { name, value } }) =>
                    handleChange(name, value)
                  }
                />
              </div>
            </div>
            <div>
              <Label>Facebook (Optional)</Label>
              <div className="mt-1">
                <TextInput
                  id="facebook"
                  name="social_fb"
                  placeholder="Facebook"
                  onChange={({ target: { name, value } }) =>
                    handleChange(name, value)
                  }
                />
              </div>
            </div>
            <div>
              <Label>X (formerly Twitter, optional)</Label>
              <div className="mt-1">
                <TextInput
                  id="x"
                  name="social_tw"
                  placeholder="X"
                  onChange={({ target: { name, value } }) =>
                    handleChange(name, value)
                  }
                />
              </div>
            </div>
            <div>
              <Label>Youtube (Optional)</Label>
              <div className="mt-1">
                <TextInput
                  id="youtube"
                  name="social_yt"
                  placeholder="Youtube"
                  onChange={({ target: { name, value } }) =>
                    handleChange(name, value)
                  }
                />
              </div>
            </div>

            <ImageUploadSection
              title={"Profile picture"}
              onImageChange={(e: any) =>
                handleChange("profile_image", {
                  uri_data: e.image,
                  file_type: e.imageType,
                  file_name: e.imageName,
                })
              }
            />

            <ImageUploadSection
              title={"Cover Image"}
              onImageChange={(e: any) =>
                handleChange("cover_image", {
                  uri_data: e.image,
                  file_type: e.imageType,
                  file_name: e.imageName,
                })
              }
            />

            <ImageUploadSection
              title={"Thumb picture"}
              onImageChange={(e: any) =>
                handleChange("thumb_image", {
                  uri_data: e.image,
                  file_type: e.imageType,
                  file_name: e.imageName,
                })
              }
            />

            <div>
              <div className="mt-1 flex w-64 flex-col space-y-4 pb-4 pl-1">
                <Label>Additional options</Label>
                <label className="relative inline-flex cursor-pointer items-center">
                  <input
                    type="checkbox"
                    name="status"
                    className="peer sr-only"
                    onChange={({ target: { name, checked } }) =>
                      handleChange(name, String(checked))
                    }
                  />
                  <div className="peer h-6 w-11 rounded-full bg-gray-200 after:absolute after:left-[2px] after:top-[2px] after:size-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-blue-800"></div>
                  <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                    Status
                  </span>
                </label>
                <label className="relative inline-flex cursor-pointer items-center">
                  <input
                    type="checkbox"
                    name="squad"
                    className="peer sr-only"
                    onChange={({ target: { name, checked } }) =>
                      handleChange(name, String(checked))
                    }
                  />
                  <div className="peer h-6 w-11 rounded-full bg-gray-200 after:absolute after:left-[2px] after:top-[2px] after:size-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-blue-800"></div>
                  <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                    Squad
                  </span>
                </label>
                <label className="relative inline-flex cursor-pointer items-center">
                  <input
                    type="checkbox"
                    name="featured"
                    className="peer sr-only"
                    onChange={({ target: { name, checked } }) =>
                      handleChange(name, String(checked))
                    }
                  />
                  <div className="peer h-6 w-11 rounded-full bg-gray-200 after:absolute after:left-[2px] after:top-[2px] after:size-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-blue-800"></div>
                  <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                    Featured
                  </span>
                </label>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            color="primary"
            onClick={handleSubmit}
            disabled={isSubmitDisabled}
          >
            {isSubmitting ? "Creating..." : "Create"}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddTrainerModal;
