import axios from "../util/axios";
import config from "../config/config";
import type { UserResponse } from "../types/apiResponses";

export async function currentUser() {
  return axios.get(`${config.defaults.api_url}/auth/current/user`);
}

export async function createNewUser(params: any) {
  return axios.post(`${config.defaults.api_url}/user/new`, params);
}

export async function updateUser(params: any, id: any) {
  return axios.post(`${config.defaults.api_url}/user/update/${id}`, params);
}

export async function deleteUser(id: any) {
  return axios.delete(`${config.defaults.api_url}/user/admin-delete/${id}`);
}

export async function getTotalUsers() {
  return axios.get(`${config.defaults.api_url}/user/metrics`);
}

export async function getStripePrices() {
  return axios.get(`${config.defaults.api_url}/payments/stripe/prices`);
}

export async function getUserDetails(id: any): Promise<UserResponse> {
  return (await axios.get(`${config.defaults.api_url}/user/${id}`)).data;
}
