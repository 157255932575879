import axios from "../util/axios";
import config from "../config/config";

export async function getAllTrainers(params: any) {
  return axios.get(`${config.defaults.api_url}/trainers`, params);
}

export async function getFeaturedTrainers() {
  return axios.get(`${config.defaults.api_url}/trainers/featured`);
}

export async function featuredTrainer(params: any, id: any) {
  return axios.post(
    `${config.defaults.api_url}/trainers/featured/${id}`,
    params,
  );
}

export async function getTotalTrainers() {
  return axios.get(`${config.defaults.api_url}/trainers/metrics`);
}

export async function updateTrainer(params: any, id: any) {
  return axios.post(`${config.defaults.api_url}/trainers/update/${id}`, params);
}

export async function deleteTrainer(id: any) {
  return axios.delete(`${config.defaults.api_url}/trainers/delete/${id}`);
}

export async function createTrainer(params: any) {
  return axios.post(`${config.defaults.api_url}/trainers/create`, params);
}
