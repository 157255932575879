import { Label, TextInput } from "flowbite-react";
import { useEffect, useMemo } from "react";

const ExerciseInput = ({
  handleChange,
  exerciseTypeId,
  commonData,
  selected,
}: any) => {
  const filteredExercises = useMemo(() => {
    if (commonData && exerciseTypeId) {
      return commonData.exercises.filter(
        (e: any) => e.exerciseType.id === exerciseTypeId,
      );
    }
    return [];
  }, [commonData, exerciseTypeId]);

  useEffect(() => {
    if (commonData && exerciseTypeId) {
      const filtered = commonData.exercises.filter(
        (e: any) => e.exerciseType.id === exerciseTypeId,
      );
      if (!filtered.some((e: any) => e.id === selected)) {
        // filtered and selected doesn't match, select default position 0
        handleChange({
          target: {
            name: "exercise",
            value: filtered[0].id,
          },
        });
      }
    }
  }, [exerciseTypeId, selected, handleChange, commonData]);
  if (exerciseTypeId) {
    return (
      <div>
        <Label>Exercise</Label>
        <div className="mt-1">
          <select
            onChange={handleChange}
            name="exercise"
            className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
          >
            <option selected disabled>
              Choose
            </option>
            {filteredExercises.map((x: any) => {
              return (
                <option key={x.id} value={x.id} selected={x.id === selected}>
                  {x.name}
                </option>
              );
            })}
          </select>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <Label>Exercise</Label>
        <div className="mt-1">
          <TextInput disabled placeholder="Select exercise type" type="text" />
        </div>
      </div>
    );
  }
};

export default ExerciseInput;
