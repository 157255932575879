/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useMemo, useRef, useState } from "react";
import {
  findVideos,
  getWorkoutPlaylist,
  savePlaylist,
} from "../../services/workouts";
import { toast } from "react-toastify";
import { Button, Label, Modal } from "flowbite-react";
import { HiTrash, HiVideoCamera } from "react-icons/hi";
import { MdDragIndicator } from "react-icons/md";
import { FaClock } from "react-icons/fa6";
import AsyncSelect from "react-select/async";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { isEqual } from "lodash";

const VideosWorkoutModal = function (props: any) {
  const [isOpen, setOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);

  const [workoutVideos, setWorkoutVideos]: any = useState([]);
  const [originalWorkoutVideos, setOriginalWorkoutVideos]: any = useState([]);

  const rootRef = useRef<HTMLDivElement>(null);

  const hasChanged = useMemo(() => {
    return !isEqual(originalWorkoutVideos, workoutVideos);
  }, [originalWorkoutVideos, workoutVideos]);

  const totalDuration = useMemo(() => {
    const totalSeconds = workoutVideos.reduce(
      (acc: number, video: any) => acc + video.video_duration,
      0,
    );
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes}:${seconds.toString().padStart(2, "0")}`;
  }, [workoutVideos]);

  const loadData = async (query: string) => {
    const response = await findVideos({
      page: 1,
      take: 20,
      search: query,
      dropdown: true,
    });
    return response.data.results.videos;
  };

  useEffect(() => {
    if (!isOpen) return;

    const init = async () => {
      setLoading(true);
      const response = await getWorkoutPlaylist(props.workout.id);
      const data = response.data?.results.map((item: any, i: any) => ({
        label: item.title,
        value: String(item.id),
        key: i,
        order: item?.order || i,
        video_duration: item.video_duration,
      }));
      setWorkoutVideos(data);
      setOriginalWorkoutVideos([...data]);
      setLoading(false);
    };

    init();
  }, [isOpen, props.workout.id]);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    await savePlaylist({ videos: workoutVideos }, props.workout.id);
    setIsSubmitting(false);
    setOpen(false);
    toast.success("Workout Playlist Saved Successfully!");
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) return;
    const items = Array.from(workoutVideos);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setWorkoutVideos(assignOrder(items));
  };

  const assignOrder = (items: any) =>
    items.map((video: any, i: any) => ({ ...video, order: i }));

  const formatDuration = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
  };

  return (
    <>
      <Button color="success" onClick={() => setOpen(true)}>
        <div className="flex items-center gap-x-3">
          <HiVideoCamera className="text-xl" />
          Playlist
        </div>
      </Button>
      <div ref={rootRef}>
        <Modal
          onClose={() => setOpen(false)}
          root={rootRef.current ?? undefined}
          show={isOpen}
          size="2xl"
          position="top-center"
        >
          <Modal.Header className="border-b border-gray-200 !p-6 dark:border-gray-700">
            <strong>Workout Playlist - {props.workout.title}</strong>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-4">
              <Label className="text-base"> Choose Videos </Label>
              <AsyncSelect
                isClearable={true}
                className="mt-2 "
                defaultValue={[]}
                loadOptions={loadData}
                onInputChange={loadData}
                noOptionsMessage={() => "No results"}
                onChange={(v: any) => {
                  if (v == null) return;
                  setWorkoutVideos(
                    assignOrder([
                      ...workoutVideos,
                      { ...v, key: workoutVideos.length },
                    ]),
                  );
                }}
                styles={{
                  control: (base) => ({
                    ...base,
                    backgroundColor: "#f9fafb", // Light gray background
                    borderColor: "rgb(156, 163, 175)", // Gray border
                  }),
                }}
              />
            </div>

            <div className="">
              {loading ? (
                <>
                  <p className="text-center">Loading...</p>
                </>
              ) : (
                <>
                  {workoutVideos?.length === 0 ? (
                    <>
                      <p className="text-center">No videos</p>
                    </>
                  ) : (
                    <>
                      <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId={`videos-${props.workout.id}`}>
                          {(provided) => (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                            >
                              {workoutVideos.map((video: any, index: any) => (
                                <Draggable
                                  key={video.key}
                                  draggableId={String(video.key)}
                                  index={index}
                                >
                                  {(provided) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      className="mt-3 flex flex-row items-center gap-1 rounded-lg border border-gray-400 bg-gray-50 px-4 py-3 text-base text-gray-800 dark:bg-gray-800 dark:text-gray-300"
                                    >
                                      <MdDragIndicator className="inline text-xl text-gray-700" />{" "}
                                      <span className="ml-2">
                                        {video.label}
                                      </span>
                                      <div className="ml-auto flex flex-row items-center">
                                        <span className=" mr-4 text-gray-400">
                                          {`(${formatDuration(
                                            video.video_duration,
                                          )})`}
                                        </span>
                                        <HiTrash
                                          onClick={async () => {
                                            const updatedVideos =
                                              workoutVideos.filter(
                                                (item: any) =>
                                                  item.key !== video.key,
                                              );

                                            // Reassign order and update state
                                            setWorkoutVideos(
                                              assignOrder(updatedVideos),
                                            );
                                          }}
                                          className="cursor-pointer text-xl text-gray-700"
                                        />
                                      </div>
                                    </div>
                                  )}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </>
                  )}

                  <div className="mt-4 flex flex-row items-center">
                    <FaClock className="mr-2 inline" />
                    <span>Total duration: {totalDuration} (per round)</span>
                  </div>
                </>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              color="primary"
              onClick={handleSubmit}
              disabled={isSubmitting || !hasChanged}
              className="px-4"
            >
              {isSubmitting ? "Saving..." : "Save"}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default VideosWorkoutModal;
