import { ActionType } from "../actionTypes/index";

const initialState = {
  user: null,
  event: null,
};

const authReducer = (state = initialState, action: any): any => {
  console.log("=== Reducer Authentication ===");
  console.log(action);
  switch (action.type) {
    case ActionType.USER_LOGIN_SUCCESS:
      return {
        ...state,
        event: action.type,
      };
    case ActionType.USER_LOGIN_FAIL:
      return {
        ...state,
        event: action.type,
      };
    case ActionType.CURRENT_USER:
      return {
        ...state,
        user: action.payload.data,
        event: action.type,
      };
    default:
      return state;
  }
};

export default authReducer;
