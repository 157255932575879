import {
  Breadcrumb,
  Button,
  Label,
  Pagination,
  TextInput,
} from "flowbite-react";
import type { FC } from "react";
import { useCallback, useEffect, useState } from "react";
import { HiHome, HiOutlinePencilAlt } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import type { RootState } from "redux/reducers/combine";
import type { ExerciseForm } from "types/apiResponses";
import NavbarSidebarLayout from "../../layouts/navbar-sidebar";
import { getAllExercises } from "../../redux/actionCreators/exercises";
import ActionType from "../../redux/actionTypes";
import { createExercise } from "../../services/exercises";
import AllExercisesTable from "./AllExercisesTable";
import ExerciseModal from "./ExerciseModal";

const MAX_CONTENT_PER_PAGE = 10;

const ExerciseListPage: FC = function () {
  const dispatch: any = useDispatch();
  const state = useSelector((state: RootState) => state);
  const [exercises, setExercises] = useState([]);
  const [isOpen, setOpen] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState("");
  const [newExercise, setNewExercise] = useState<ExerciseForm>({
    id: "",
    name: "",
    exerciseType: "",
    muscles: [],
  });

  const onPageChange = (page: number) => {
    setCurrentPage(page);
  };

  const getExercises = useCallback(
    async (page: any = currentPage, search: any = "") => {
      setIsLoading(true);
      dispatch(
        getAllExercises({
          page: page,
          take: MAX_CONTENT_PER_PAGE,
          search,
        }),
      );
    },
    [currentPage, dispatch],
  );

  useEffect(() => {
    setTotalPages(Math.ceil(state.exercises.total / MAX_CONTENT_PER_PAGE));
  }, [state.exercises.total]);

  useEffect(() => {
    getExercises(currentPage);
  }, [currentPage, getExercises]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getExercises(currentPage, search);
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [search, currentPage, getExercises]);

  useEffect(() => {
    if (state.exercises.event === ActionType.GET_ALL_EXERCISES) {
      setExercises(state.exercises.all);
      setIsLoading(false);
    } else if (state?.exercises?.type == ActionType.GET_ALL_EXERCISES_FAILED) {
      console.log("Error : Unable to fetch exercises");
      setIsLoading(false);
    }
  }, [state]);

  const handleSubmitCreate = async () => {
    setIsLoading(true);
    const createResponse = await createExercise(newExercise);

    if (createResponse.data.error) {
      toast.error(createResponse.data.message);
    } else {
      getExercises();
      toast.success("Exercise created successfully!");
    }
    setIsLoading(false);
  };

  return (
    <NavbarSidebarLayout isFooter={false}>
      <div className="block items-center justify-between border-b border-gray-200 bg-white p-4 dark:border-gray-700 dark:bg-gray-800 sm:flex">
        <div className="mb-1 w-full">
          <div className="mb-4">
            <Breadcrumb className="mb-4">
              <Breadcrumb.Item href="#">
                <div className="flex items-center gap-x-3">
                  <HiHome className="text-xl" />
                  <span className="dark:text-white">Home</span>
                </div>
              </Breadcrumb.Item>
              <Breadcrumb.Item href="/exercises">Exercises</Breadcrumb.Item>
              <Breadcrumb.Item>List</Breadcrumb.Item>
            </Breadcrumb>
            <h1 className="text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl">
              All Exercises
            </h1>
          </div>
          <div className="sm:flex">
            <div className="mb-3 hidden items-center dark:divide-gray-700 sm:mb-0 sm:flex sm:divide-x sm:divide-gray-100">
              <form className="lg:pr-3">
                <Label htmlFor="exercises-search" className="sr-only">
                  Search
                </Label>
                <div className="relative mt-1 lg:w-64 xl:w-96">
                  <TextInput
                    id="exercises-search"
                    name="exercises-search"
                    placeholder="Search for exercises"
                    onChange={async (e: any) => {
                      setSearch(e.target.value);
                    }}
                  />
                </div>
              </form>
            </div>
            <div className="ml-auto flex items-center space-x-2 sm:space-x-3">
              <Button color="primary" onClick={() => setOpen(true)}>
                <div className="flex items-center gap-x-2">
                  <HiOutlinePencilAlt className="text-lg" />
                  Add Exercise
                </div>
              </Button>
              {isOpen && (
                <ExerciseModal
                  isOpen={isOpen}
                  exercise={newExercise}
                  updating={loading}
                  setExercise={setNewExercise}
                  handleSubmit={handleSubmitCreate}
                  handleClose={() => setOpen(false)}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="mb-8 flex flex-col">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full align-middle">
            <div className="overflow-hidden shadow">
              {loading ? (
                <div role="status" className="size-full text-center">
                  <svg
                    aria-hidden="true"
                    className=" mx-auto my-10 size-8  animate-spin fill-blue-600  text-center text-gray-200 dark:text-gray-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>

                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                <>
                  <AllExercisesTable
                    exercises={exercises}
                    getExercises={getExercises}
                    loading={loading}
                  />
                  {state.exercises.all?.length > 0 && (
                    <Pagination
                      currentPage={currentPage}
                      onPageChange={onPageChange}
                      showIcons
                      aria-disabled={true}
                      totalPages={totalPages}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
        theme="dark"
      />
    </NavbarSidebarLayout>
  );
};

export default ExerciseListPage;
