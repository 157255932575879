import { Label, TextInput } from "flowbite-react";
import { useMemo } from "react";

const RepsInput = ({
  commonData,
  exerciseTypeId,
  handleChange,
  value,
}: any) => {
  const repsType = useMemo(() => {
    // Dropdown or Text
    if (commonData && exerciseTypeId) {
      if (
        exerciseTypeId ===
        commonData.exerciseTypes.find((e: any) => e.name === "Hiit")?.id
      ) {
        if (!value.includes("seconds")) {
          handleChange({
            target: {
              name: "rep_count",
              value: "30 seconds",
            },
          });
        }
        return "Dropdown";
      } else {
        if (value.includes("seconds")) {
          handleChange({
            target: {
              name: "rep_count",
              value: value.replace(/\D/g, ""),
            },
          });
        }
        return "Text";
      }
    }
    return "Disabled";
  }, [commonData, exerciseTypeId, handleChange, value]);

  switch (repsType) {
    case "Text":
      return (
        <div>
          <Label>Reps</Label>
          <div className="mt-1">
            <TextInput
              id="rep_count"
              name="rep_count"
              placeholder="Reps"
              onChange={handleChange}
              type="text"
              value={value}
            />
          </div>
        </div>
      );
    case "Dropdown":
      return (
        <div>
          <Label>Reps</Label>
          <div className="mt-1">
            <select
              onChange={handleChange}
              name="rep_count"
              className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
            >
              <option selected={value == null} disabled>
                Choose
              </option>
              <option selected={value === "30 seconds"} value={"30 seconds"}>
                {"30 seconds"}
              </option>
              <option selected={value === "60 seconds"} value={"60 seconds"}>
                {"60 seconds"}
              </option>
            </select>
          </div>
        </div>
      );
    case "Disabled":
      return (
        <div>
          <Label>Reps</Label>
          <div className="mt-1">
            <TextInput
              disabled
              id="rep_count"
              name="rep_count"
              placeholder="Select exercise type"
              onChange={handleChange}
              type="text"
            />
          </div>
        </div>
      );
    default:
      return (
        <div>
          <Label>Reps</Label>
          <div className="mt-1">
            <TextInput
              disabled
              id="rep_count"
              name="rep_count"
              placeholder="Select exercise"
              onChange={handleChange}
              type="text"
              value={value}
            />
          </div>
        </div>
      );
  }
};

export default RepsInput;
