import axios from "../../util/axios";
import { ActionType } from "../../redux/actionTypes/index";
import type { Dispatch } from "redux";
import cookie from "js-cookie";
import config from "../../config/config";

export function login(params: any) {
  return async function (dispatch: Dispatch) {
    try {
      const data = {
        email: params.email,
        password: params.password,
      };

      await axios
        .post(`${config.defaults.api_url}/auth/login`, data, {
          method: "POST",
        })
        .then((user: any) => {
          console.log(user.data);

          cookie.remove("token");
          cookie.remove("refresh_token");

          cookie.set("token", user.data.results.access_token, { expires: 400 });

          cookie.set("refresh_token", user.data.results.refresh_token, {
            expires: 400,
          });

          dispatch({
            type: ActionType.USER_LOGIN_SUCCESS,
            payload: user,
          });
        });
    } catch (error) {
      console.log(error);

      dispatch({
        type: ActionType.USER_LOGIN_FAIL,
        payload: {
          message:
            "The Email Address or Password entered is invalid. Please try again.",
        },
      });
    }
  };
}

export function fetchCurrentUser() {
  return async function (dispatch: Dispatch) {
    try {
      await axios
        .get(`${config.defaults.api_url}/auth/current/user`)
        .then((user: any) => {
          dispatch({
            type: ActionType.CURRENT_USER,
            payload: user,
          });
        });
    } catch (error) {
      console.log(error);

      dispatch({
        type: ActionType.CURRENT_USER_FAILED,
        payload: {
          message: "Unable to get current logged in user details",
        },
      });
    }
  };
}
