import { Button } from "flowbite-react";

const ErrorTestButton = () => {
  return localStorage.getItem("enableErrorTesting") === "true" ? (
    <Button
      color="failure"
      className="mt-4"
      onClick={() => {
        throw new Error("Test Error React");
      }}
    >
      Generate Error
    </Button>
  ) : null;
};

export default ErrorTestButton;
