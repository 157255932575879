import type { Reducer } from "@reduxjs/toolkit";
import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import reducers from "./reducers/combine";

const persistConfig = {
  key: "root",
  storage,
};

const isLocalhost =
  window.location.hostname === "localhost" ||
  window.location.hostname === "127.0.0.1";
const isDev = window.location.hostname === "manage-dev.sweatsonic.com";

const persistedReducer = persistReducer(persistConfig, reducers as Reducer);

const store = configureStore({
  reducer: persistedReducer,
  devTools: isLocalhost || isDev,
});

const persistor = persistStore(store);

export { store, persistor };
