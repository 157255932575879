import cookie from "js-cookie";
import axios from "axios";
import config from "../config/config";

let refreshTokenPromise: any = null;
const subscribers: any = [];

function onTokenRefreshed(accessToken: any) {
  subscribers.forEach((callback: any) => callback(accessToken));
  subscribers.length = 0;
}

function addSubscriber(callback: any) {
  subscribers.push(callback);
}

function redirectToLogin() {
  refreshTokenPromise = null;
  cookie.remove("token");
  cookie.remove("refresh_token");
  window.location.href = "/login";
}

async function refreshToken(): Promise<string> {
  if (!refreshTokenPromise) {
    refreshTokenPromise = (async () => {
      try {
        const refreshToken = cookie.get("refresh_token");

        if (!refreshToken?.trim()) {
          console.log("No refresh token found");
          throw new Error("No refresh token found");
        }

        const response = await fetch(
          `${config.defaults.api_url}/auth/refresh-token`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ refresh_token: refreshToken }),
          },
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        const newToken = data.access_token;

        cookie.set("token", newToken, { expires: 400 });
        axios.defaults.headers.common.Authorization = `Bearer ${newToken}`;

        return newToken;
      } catch {
        throw new Error("Failed to refresh token.");
      } finally {
        refreshTokenPromise = null;
      }
    })();
  }

  return refreshTokenPromise;
}

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token") ?? cookie.get("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const { config, response } = error;
    const originalRequest = config;

    if (response && response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      return new Promise((resolve) => {
        addSubscriber((accessToken: any) => {
          originalRequest.headers.Authorization = `Bearer ${accessToken}`;
          resolve(axios(originalRequest));
        });
        refreshToken()
          .then(onTokenRefreshed)
          .catch(() => {
            console.log("Unable to refresh the access token, redirecting.");
            redirectToLogin();
          });
      });
    }
    return Promise.reject(error);
  },
);

export default axios;
