/* eslint-disable tailwindcss/no-custom-classname */
import { Label } from "flowbite-react";
import { useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";

const ImageUploadSection = ({ title, onImageChange, defaultPreview }: any) => {
  const fileTypes = ["JPG", "PNG", "JPEG"];

  const [, setImageName] = useState();
  const [previewUrl, setPreviewUrl]: any = useState();
  const [, setImageType] = useState();
  const [, setImage]: any = useState();

  useEffect(() => {
    setPreviewUrl(defaultPreview);
  }, [defaultPreview]);

  const handleFileChange = (file: any) => {
    const img = new Image();
    img.src = window.URL.createObjectURL(file);
    img.onload = () => {
      setPreviewUrl(URL.createObjectURL(file));

      setImageName(file.name);

      setImageType(file.type);

      const reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => {
        setImage(reader.result);
        onImageChange({
          previewUrl: URL.createObjectURL(file),
          imageName: file.name,
          imageType: file.type,
          image: reader.result,
        });
      };
    };
  };

  return (
    <div>
      <Label>{title}</Label>
      <FileUploader
        name="file"
        handleChange={handleFileChange}
        types={fileTypes}
      >
        <div className="mt-1">
          <div className="flex w-full items-center justify-center">
            <label
              htmlFor="dropzone-file"
              className="dark:hover:bg-bray-800 flex h-64 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 hover:bg-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:border-gray-500 dark:hover:bg-gray-600"
            >
              <div className="flex flex-col items-center justify-center pb-6 pt-5">
                {previewUrl ? (
                  <>
                    <img src={previewUrl} alt="" width={150} height={150} />

                    <p className="mt-3 text-xs text-gray-500 dark:text-gray-400">
                      Click to replace
                    </p>
                  </>
                ) : (
                  <>
                    <svg
                      className="mb-4 size-8 text-gray-500 dark:text-gray-400"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 20 16"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                      />
                    </svg>
                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                      <span className="font-semibold">Click to upload</span> or
                      drag and drop
                    </p>
                    <p className="text-xs text-gray-500 dark:text-gray-400">
                      PNG, JPG (MAX. 800x400px)
                    </p>
                  </>
                )}
              </div>
            </label>
          </div>
        </div>
      </FileUploader>
    </div>
  );
};

export default ImageUploadSection;
