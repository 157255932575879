/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Label, Modal, TextInput, Textarea } from "flowbite-react";
import { useEffect, useMemo, useRef, useState } from "react";
import { HiOutlinePencilAlt } from "react-icons/hi";
import { toast } from "react-toastify";
import { updateTrainer } from "../../services/trainers";
import ImageUploadSection from "./ImageUploadSection";
import FavoriteTable, { FavoriteType } from "./FavoriteTable";
import type { FavoriteItem } from "./FavoriteTable";

const EditTrainerModal = function (props: any) {
  const [isOpen, setOpen] = useState(false);
  const [updating, setUpdate] = useState(false);
  const [trainer, setTrainer]: any = useState({});
  const [favouriteVideos, setFavouriteVideos] = useState<FavoriteItem[]>([]);
  const [favouritePrograms, setFavouritePrograms] = useState<FavoriteItem[]>(
    [],
  );
  const [favouriteWorkouts, setFavouriteWorkouts] = useState<FavoriteItem[]>(
    [],
  );

  const handleChange = (name: string, value: any) => {
    const data: any = {
      ...trainer,
      [name]: value,
    };
    setTrainer(data);
  };

  const rootRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (props.trainer) {
      setFavouriteVideos(props.trainer.favourite_videos || []);
      setFavouriteWorkouts(props.trainer.favourite_workouts || []);
      setFavouritePrograms(props.trainer.favourite_programs || []);
    }
  }, [props.trainer]);

  const handleSubmit = async () => {
    setUpdate(true);

    const update_trainer = await updateTrainer(
      {
        ...trainer,
        favourite_videos_ids: favouriteVideos.map((item) => item.id),
        favourite_workouts_ids: favouriteWorkouts.map((item) => item.id),
        favourite_programs_ids: favouritePrograms.map((item) => item.id),
      },
      props.trainer?.id,
    );

    if (update_trainer.data.error) {
      toast.error(update_trainer.data.message);
    } else {
      props.getTrainers();
      setOpen(false);
      toast.success("Trainer updated successfully!");
    }

    setUpdate(false);
  };

  const isModified = useMemo(() => {
    // Check if any of the trainer's fields have changed
    const trainerChanged = Object.keys(trainer).some(
      (key) => trainer[key] !== props.trainer[key],
    );

    // Check if favourite videos have changed
    const videosChanged =
      JSON.stringify(favouriteVideos) !==
      JSON.stringify(props.trainer.favourite_videos);

    // Check if favourite workouts have changed
    const workoutsChanged =
      JSON.stringify(favouriteWorkouts) !==
      JSON.stringify(props.trainer.favourite_workouts);

    // Check if favourite programs have changed
    const programsChanged =
      JSON.stringify(favouritePrograms) !==
      JSON.stringify(props.trainer.favourite_programs);

    return (
      trainerChanged || videosChanged || workoutsChanged || programsChanged
    );
  }, [
    trainer,
    favouriteVideos,
    props.trainer,
    favouriteWorkouts,
    favouritePrograms,
  ]);

  return (
    <>
      <div ref={rootRef}>
        <Button color="primary" onClick={() => setOpen(true)}>
          <div className="flex items-center gap-x-2">
            <HiOutlinePencilAlt className="text-lg" />
            Edit Athlete
          </div>
        </Button>
        <Modal
          onClose={() => setOpen(false)}
          show={isOpen}
          size="3xl"
          position="top-center"
          root={rootRef.current ?? undefined}
        >
          <Modal.Header className="border-b border-gray-200 !p-6 dark:border-gray-700">
            <strong>Edit Athlete</strong>
          </Modal.Header>
          <Modal.Body className="overflow-visible">
            <div className="mb-1 block">
              <Label htmlFor="firstName">Bio</Label>
              <div className="mt-1">
                <Textarea
                  id="comment"
                  name="description"
                  required
                  rows={1}
                  defaultValue={props.trainer.description}
                  placeholder="Bio"
                  onChange={({ target: { name, value } }) =>
                    handleChange(name, value)
                  }
                />
              </div>
            </div>
            <div className="grid grid-cols-1 gap-4 overflow-visible sm:grid-cols-2">
              <div>
                <Label htmlFor="firstName">Monthly Plays</Label>
                <div className="mt-1">
                  <TextInput
                    id="monthly_plays"
                    name="monthly_plays"
                    defaultValue={props.trainer.monthly_plays}
                    placeholder="Monthly Plays"
                    onChange={({ target: { name, value } }) =>
                      handleChange(name, value)
                    }
                  />
                </div>
              </div>
              <div>
                <Label htmlFor="firstName">Total Followers</Label>
                <div className="mt-1">
                  <TextInput
                    id="followers"
                    name="followers"
                    defaultValue={props.trainer.total_followers}
                    placeholder="Followers"
                    onChange={({ target: { name, value } }) =>
                      handleChange(name, value)
                    }
                  />
                </div>
              </div>
              <div>
                <Label htmlFor="firstName">Instagram</Label>
                <div className="mt-1">
                  <TextInput
                    id="instagram"
                    name="social_ig"
                    defaultValue={props.trainer.social_ig}
                    placeholder="Instagram"
                    onChange={({ target: { name, value } }) =>
                      handleChange(name, value)
                    }
                  />
                </div>
              </div>
              <div>
                <Label htmlFor="firstName">TikTok</Label>
                <div className="mt-1">
                  <TextInput
                    id="tiktok"
                    name="social_tt"
                    defaultValue={props.trainer.social_tt}
                    placeholder="Tiktok"
                    onChange={({ target: { name, value } }) =>
                      handleChange(name, value)
                    }
                  />
                </div>
              </div>
              <div>
                <Label htmlFor="firstName">Facebook</Label>
                <div className="mt-1">
                  <TextInput
                    id="facebook"
                    name="social_fb"
                    defaultValue={props.trainer.social_fb}
                    placeholder="Facebook"
                    onChange={({ target: { name, value } }) =>
                      handleChange(name, value)
                    }
                  />
                </div>
              </div>
              <div>
                <Label htmlFor="firstName">X (formerly Twitter)</Label>
                <div className="mt-1">
                  <TextInput
                    id="x"
                    name="social_tw"
                    defaultValue={props.trainer.social_tw}
                    placeholder="X"
                    onChange={({ target: { name, value } }) =>
                      handleChange(name, value)
                    }
                  />
                </div>
              </div>

              <ImageUploadSection
                title={"Profile picture"}
                defaultPreview={props.trainer.profile_image}
                onImageChange={(e: any) =>
                  handleChange("profile_image", {
                    uri_data: e.image,
                    file_type: e.imageType,
                    file_name: e.imageName,
                  })
                }
              />
              <ImageUploadSection
                title={"Cover Image"}
                defaultPreview={props.trainer.cover_image}
                onImageChange={(e: any) =>
                  handleChange("cover_image", {
                    uri_data: e.image,
                    file_type: e.imageType,
                    file_name: e.imageName,
                  })
                }
              />
              <ImageUploadSection
                title={"Thumb picture"}
                defaultPreview={props.trainer.thumb_image}
                onImageChange={(e: any) =>
                  handleChange("thumb_image", {
                    uri_data: e.image,
                    file_type: e.imageType,
                    file_name: e.imageName,
                  })
                }
              />
              <div>
                <div className="mt-1 flex w-64 flex-col space-y-4 pb-4 pl-1">
                  <Label>Additional options</Label>
                  <label className="relative inline-flex cursor-pointer items-center">
                    <input
                      type="checkbox"
                      defaultChecked={props.trainer.status ? true : false}
                      name="status"
                      className="peer sr-only"
                      onChange={({ target: { name, checked } }) =>
                        handleChange(name, String(checked))
                      }
                    />
                    <div className="peer h-6 w-11 rounded-full bg-gray-200 after:absolute after:left-[2px] after:top-[2px] after:size-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-blue-800"></div>
                    <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                      Status
                    </span>
                  </label>
                </div>
              </div>

              <div className="">
                <Label>Favorite Workouts</Label>
                <FavoriteTable
                  items={favouriteWorkouts}
                  onClearAll={() => setFavouriteWorkouts([])}
                  onRemove={(id: number) => {
                    setFavouriteWorkouts((workouts: FavoriteItem[]) =>
                      workouts.filter((workout) => workout.id !== id),
                    );
                  }}
                  onSelect={(item: FavoriteItem) =>
                    setFavouriteWorkouts((workouts: FavoriteItem[]) =>
                      workouts.concat(item),
                    )
                  }
                  type={FavoriteType.Workouts}
                />
              </div>
              <div className="">
                <Label>Favorite Videos</Label>
                <FavoriteTable
                  items={favouriteVideos}
                  onClearAll={() => setFavouriteVideos([])}
                  onRemove={(id: number) => {
                    setFavouriteVideos((videos: FavoriteItem[]) =>
                      videos.filter((video) => video.id !== id),
                    );
                  }}
                  onSelect={(item: FavoriteItem) =>
                    setFavouriteVideos((videos: FavoriteItem[]) =>
                      videos.concat(item),
                    )
                  }
                  type={FavoriteType.Videos}
                />
              </div>
              <div className="">
                <Label>Favorite Programs</Label>
                <FavoriteTable
                  items={favouritePrograms}
                  onClearAll={() => setFavouritePrograms([])}
                  onRemove={(id: number) => {
                    setFavouritePrograms((programs: FavoriteItem[]) =>
                      programs.filter((program) => program.id !== id),
                    );
                  }}
                  onSelect={(item: FavoriteItem) =>
                    setFavouritePrograms((programs: FavoriteItem[]) =>
                      programs.concat(item),
                    )
                  }
                  type={FavoriteType.Programs}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              color="primary"
              disabled={updating || !isModified}
              onClick={handleSubmit}
            >
              {updating ? "Updating..." : "Update"}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default EditTrainerModal;
