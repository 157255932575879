export enum ActionType {
  // User Actions
  CURRENT_USER = "CURRENT_USER",
  CURRENT_USER_FAILED = "CURRENT_USER_FAILED",
  USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS",
  USER_LOGIN_FAIL = "USER_LOGIN_FAIL",

  GET_ALL_USERS = "GET_ALL_USERS",
  GET_ALL_USERS_FAILED = "GET_ALL_USERS_FAILED",

  GET_ALL_WORKOUTS = "GET_ALL_WORKOUTS",
  GET_ALL_WORKOUTS_FAILED = "GET_ALL_WORKOUTS_FAILED",

  GET_ALL_PROGRAMS = "GET_ALL_PROGRAMS",
  GET_ALL_PROGRAMS_FAILED = "GET_ALL_PROGRAMS_FAILED",

  GET_ALL_VIDEOS = "GET_ALL_VIDEOS",
  GET_ALL_VIDEOS_FAILED = "GET_ALL_VIDEOS_FAILED",

  GET_ALL_TRAINERS = "GET_ALL_TRAINERS",
  GET_ALL_TRAINERS_FAILED = "GET_ALL_TRAINERS_FAILED",

  TRAINERS_LOADING = "TRAINERS_LOADING",
  TRAINERS_LOADING_DONE = "TRAINERS_LOADING_DONE",

  GET_ALL_EXERCISES = "GET_ALL_EXERCISES",
  GET_ALL_EXERCISES_FAILED = "GET_ALL_EXERCISES_FAILED",
}

export default ActionType;
