import axios from "axios";
import { Button, Label, Modal, TextInput, Textarea } from "flowbite-react";
import { useEffect, useMemo, useRef, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import config from "../../config/config";
import { updateVideo } from "../../services/videos";
import DropdownCheckbox from "./DropdownCheckbox";
import ExerciseInput from "./ExerciseInput";
import RepsInput from "./RepsInput";

const EditVideoModal = (props: any) => {
  const rootRef = useRef<HTMLDivElement>(null);

  const [, setOpen] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [video, setVideo] = useState({
    muscle_target: 0,
    workout_video: "",
    exercise_explained_video: "",
    trainer: 0,
    title: "",
    points: 0,
    reps: 0,
    video_duration: 0,
    brief: "",
    category: 0,
    published: false,
    video_thumbnail: "",
    video_thumbnail_type: "",
    workout_video_name: "",
    workout_video_type: "",
    explained_video_name: "",
    explained_video_type: "",
    image_name: "",
    image_type: "",
    exercise: "",
  } as any);

  const fileTypes = ["JPG", "PNG", "JPEG"];

  const [image, setImage] = useState(null);
  const [image_name, setImageName]: any = useState(null);
  const [image_type, setImageType] = useState(null);

  const [workout_video, setWorkoutVideo]: any = useState(null);
  const [workout_video_name, setWorkoutVideoName]: any = useState(
    props.video?.video_filename || "video.mp4",
  );
  const [workout_video_type, setWorkoutVideoType] = useState(null);
  const [workout_video_size, setWorkoutVideoSize]: any = useState(0);
  const [workout_video_progress, setWorkoutVideoProgress]: any = useState(0);
  const [workout_video_loaded, setWorkoutVideoLoaded]: any = useState(0);

  const [explained_video, setExplainedVideo]: any = useState(
    props.video?.exercise_explained_video_url || null,
  );
  const [explained_video_name, setExplainedVideoName] = useState(
    props.video?.exercise_explained_video_filename,
  );
  const [explained_video_type, setExplainedVideoType] = useState(null);

  const [explained_video_size, setExplainedVideoSize]: any = useState(0);
  const [explained_video_progress, setExplainedVideoProgress]: any =
    useState(0);
  const [explained_video_loaded, setExplainedVideoLoaded]: any = useState(0);

  const [previewUrl, setPreviewUrl] = useState(null);

  const [exerciseTypeId, setExerciseType] = useState<string>();
  const [allMuscles] = useState<any>(props?.commonData?.muscles);
  const [selectedMuscles, setSelectedMuscles] = useState();

  useEffect(() => {
    if (props.commonData && video.exercise) {
      const muscles = props.commonData.exercises.find(
        (e: any) => e.id === video.exercise,
      ).muscles;
      setSelectedMuscles(muscles);
    }
  }, [props.commonData, video.exercise]);

  const handleThumbnailChange = (file: any) => {
    console.log("=== Image Upload ===");

    console.log(file);

    const img = new Image();
    img.src = window.URL.createObjectURL(file);
    img.onload = () => {
      // @ts-expect-error:next-line

      setPreviewUrl(URL.createObjectURL(file));

      console.log("Preview ", URL.createObjectURL(file));

      setImageName(file.name);

      setImageType(file.type);

      const reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => {
        console.log(reader.result);

        // @ts-expect-error:next-line
        setImage(reader.result);
      };
    };
  };

  const handleWorkoutVideoFileChange = (file: any) => {
    console.log(file);

    setWorkoutVideoName(file.name);

    setWorkoutVideoType(file.type);

    const date = new Date();
    const seconds = date.getTime() / 1000;
    const file_name = `${seconds}-${uuidv4()}`;

    setWorkoutVideo(file_name);

    setWorkoutVideoSize((file.size / (1024 * 1024)).toFixed(2));

    const formData = new FormData();

    formData.append("file", file);

    axios
      .post(`${config.defaults.api_url}/user/video`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress(progressEvent: any) {
          console.log("==== Progress Video =====");
          console.log(progressEvent);

          setWorkoutVideoProgress(true);

          setWorkoutVideoLoaded((file.size / (1024 * 1024)).toFixed(2));
        },
      })
      .then((data: any) => {
        setWorkoutVideoProgress(false);
        toast.success("Workout video uploaded to storage successfully!");

        console.log(data?.data?.Key);
        setWorkoutVideo(data?.data?.Key);
      })
      .catch(() => {
        console.log("=== Workout video upload error ===");
        alert("There is some error uploading file. please try again later");
        setWorkoutVideoProgress(false);
      });
  };

  const handleExplainedVideoFileChange = (file: any) => {
    console.log(file);

    setExplainedVideoName(file.name);

    setExplainedVideoType(file.type);

    const date = new Date();
    const seconds = date.getTime() / 1000;
    const file_name = `${seconds}-${uuidv4()}`;

    console.log("===== Explained Video File Name Workout: ====", file_name);

    setExplainedVideo(file_name);

    setExplainedVideoSize((file.size / (1024 * 1024)).toFixed(2));

    const formData = new FormData();

    formData.append("file", file);

    axios
      .post(`${config.defaults.api_url}/user/video`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress(progressEvent) {
          console.log("==== Progress Video =====");
          console.log(progressEvent);

          setExplainedVideoProgress(true);

          setExplainedVideoLoaded((file.size / (1024 * 1024)).toFixed(2));
        },
      })
      .then((data: any) => {
        setExplainedVideoProgress(false);

        toast.success("Explained video uploaded to storage successfully!");
        console.log(data?.data?.Key);
        setExplainedVideo(data?.data?.Key);
      })
      .catch(() => {
        console.log("=== Workout video upload error ===");
        alert("There is some error uploading file. please try again later");
        setExplainedVideoProgress(false);
      });
  };

  const handleChange = (e: any) => {
    const data: any = {
      ...video,
    };

    data[e.target.name] =
      e.target.name === "published" ? e.target.checked : e.target.value;

    console.log(data);

    setVideo(data);
  };

  useEffect(() => {
    const {
      trainer,
      title,
      points,
      video_duration,
      brief,
      published,
      setCount,
      repCount,
      exercise,
      equipment,
      musicGenre,
      musicArtist,
    } = props.video;
    setVideo({
      trainer: trainer?.id || "",
      title: title,
      points: points,
      set_count: setCount,
      rep_count: repCount,
      video_duration: video_duration,
      muscles: exercise?.muscles?.map((e: any) => e?.id) || [],
      exercise: exercise?.id || "",
      equipment: equipment?.id || "",
      music_genre: musicGenre?.id || "",
      music_artist: musicArtist,
      brief: brief,
      published: published,
      video_thumbnail: true as any,
      video_thumbnail_type: "",
      workout_video_type: "",
      explained_video_type: "",
      image_name: "Img-03",
      image_type: "",
    });
    if (props.commonData && exercise?.id) {
      const newTypeId = props.commonData.exercises.find(
        (e: any) => e.id === exercise?.id,
      ).exerciseType.id;

      setExerciseType(newTypeId);
    }
  }, [props.video, props.commonData]);

  useEffect(() => {
    console.log("[DEBUG] Video Object ", video);
  }, [video]);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const update_video = await updateVideo(
      {
        ...video,
        video_thumbnail: image,
        image_name: image_name,
        image_type: image_type,
        workout_video: workout_video,
        workout_video_name: workout_video_name,
        workout_video_type: workout_video_type,
        exercise_explained_video: explained_video,
        explained_video_name: explained_video_name,
        explained_video_type: explained_video_type,
      },
      props.video.id,
    );

    console.log("=== New Video ===");

    console.log(update_video);

    if (update_video.data.error) {
      toast.error(update_video.data.message);
    } else {
      props.getVideos();
      setOpen(false);
      toast.success("Video updated successfully!");
    }

    setIsSubmitting(false);
  };

  const trainerGender = useMemo(() => {
    if (props.commonData?.trainers) {
      const trainerId = parseInt(video.trainer);
      const currentTrainer = props.commonData.trainers.find(
        (e: any) => e.id === trainerId,
      );
      if (currentTrainer) {
        return currentTrainer.gender ? "Female" : "Male";
      }
    }
    return "Gender";
  }, [video, props.commonData]);

  const isSubmitDisabled = useMemo(() => {
    // Required Fields
    const fieldValidation = [
      "muscles",
      "equipment",
      "music_genre",
      "music_artist",
      "exercise",
      "video_duration",
      "rep_count",
      "set_count",
      "points",
      "title",
      "trainer",
    ];

    // True if there is an empty field
    const isEmptyField = fieldValidation.some((field: string) => {
      if (Object.hasOwn(video, field)) {
        const len = (video as any)[field]?.length;
        return len === 0;
      }
      return true;
    });

    return (
      isEmptyField ||
      isSubmitting ||
      workout_video_progress ||
      explained_video_progress
    );
  }, [video, explained_video_progress, workout_video_progress, isSubmitting]);

  if (props?.commonData && video.title) {
    return (
      <>
        <div ref={rootRef}>
          <Modal
            onClose={() => props.onClose()}
            show={true}
            size="3xl"
            position="top-center"
            root={rootRef.current ?? undefined}
          >
            <Modal.Header className="border-b border-gray-200 !p-6 dark:border-gray-700">
              <strong>Edit Video</strong>
            </Modal.Header>
            <Modal.Body>
              <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                <div>
                  <Label htmlFor="firstName">Title</Label>
                  <div className="mt-1">
                    <TextInput
                      id="title"
                      name="title"
                      placeholder="Title"
                      onChange={handleChange}
                      defaultValue={video.title}
                    />
                  </div>
                </div>
                <div>
                  <Label>{"Brief (Optional)"}</Label>
                  <div className="mt-1">
                    <Textarea
                      id="brief"
                      name="brief"
                      placeholder="Brief"
                      onChange={handleChange}
                      defaultValue={video.brief}
                    />
                  </div>
                </div>

                <div>
                  <Label>Workout Video</Label>
                  <div className="mt-1">
                    <FileUploader
                      name="file"
                      handleChange={handleWorkoutVideoFileChange}
                      types={["mp4", "MP4"]}
                    >
                      <div className="mt-1">
                        <div className="flex w-full items-center justify-center">
                          <label
                            htmlFor="dropzone-file"
                            className="flex h-64 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 hover:bg-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                          >
                            <div className="flex flex-col items-center justify-center pb-6 pt-5">
                              {props.video?.video_url ? (
                                <>
                                  <p className="text-black">
                                    {workout_video_name}
                                  </p>

                                  <p className="mt-3 text-xs text-gray-500 dark:text-gray-400">
                                    Click to replace
                                  </p>
                                </>
                              ) : (
                                <>
                                  {workout_video ? (
                                    <>
                                      <p className="text-black">
                                        {workout_video_name}
                                      </p>

                                      <p className="mt-3 text-xs text-gray-500 dark:text-gray-400">
                                        Click to replace
                                      </p>
                                    </>
                                  ) : (
                                    <>
                                      <svg
                                        className="mb-4 size-8 text-gray-500 dark:text-gray-400"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 20 16"
                                      >
                                        <path
                                          stroke="currentColor"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeWidth="2"
                                          d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                                        />
                                      </svg>
                                      <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                        <span className="font-semibold">
                                          Click to upload
                                        </span>{" "}
                                        or drag and drop
                                      </p>
                                      <p className="text-xs text-gray-500 dark:text-gray-400">
                                        MP4 File Allowed Only
                                      </p>
                                    </>
                                  )}
                                </>
                              )}
                            </div>
                          </label>
                        </div>
                      </div>
                    </FileUploader>
                  </div>
                  {/* workout_video */}

                  <div className="mt-3 text-[11px]">
                    <p>
                      Total Size: {workout_video_size} MB | Status:{" "}
                      {workout_video_progress
                        ? `In Progress ${workout_video_loaded} MB`
                        : "-"}
                    </p>
                  </div>
                </div>

                <div>
                  <Label>Video Thumbnail</Label>
                  <FileUploader
                    name="file"
                    handleChange={handleThumbnailChange}
                    types={fileTypes}
                  >
                    <div className="mt-1">
                      <div className="flex w-full items-center justify-center">
                        <label
                          htmlFor="dropzone-file"
                          className="flex h-64 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 hover:bg-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                        >
                          <div className="flex flex-col items-center justify-center pb-6 pt-5">
                            {props.video.video_thumbnail_image ? (
                              <>
                                {previewUrl ? (
                                  <>
                                    <img
                                      src={previewUrl}
                                      alt=""
                                      width={150}
                                      height={150}
                                    />

                                    <p className="mt-3 text-xs text-gray-500 dark:text-gray-400">
                                      Click to replace
                                    </p>
                                  </>
                                ) : (
                                  <>
                                    <img
                                      src={props.video.video_thumbnail_image}
                                      alt=""
                                      width={150}
                                      height={150}
                                    />

                                    <p className="mt-3 text-xs text-gray-500 dark:text-gray-400">
                                      Click to replace
                                    </p>
                                  </>
                                )}
                              </>
                            ) : (
                              <>
                                {previewUrl ? (
                                  <>
                                    <img
                                      src={previewUrl}
                                      alt=""
                                      width={150}
                                      height={150}
                                    />

                                    <p className="mt-3 text-xs text-gray-500 dark:text-gray-400">
                                      Click to replace
                                    </p>
                                  </>
                                ) : (
                                  <>
                                    <svg
                                      className="mb-4 size-8 text-gray-500 dark:text-gray-400"
                                      aria-hidden="true"
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 20 16"
                                    >
                                      <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                                      />
                                    </svg>
                                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                      <span className="font-semibold">
                                        Click to upload
                                      </span>{" "}
                                      or drag and drop
                                    </p>
                                    <p className="text-xs text-gray-500 dark:text-gray-400">
                                      PNG, JPG (MAX. 800x400px)
                                    </p>
                                  </>
                                )}
                              </>
                            )}
                          </div>
                        </label>
                      </div>
                    </div>
                  </FileUploader>
                </div>
                <div>
                  <Label>Select Trainer</Label>
                  <div className="mt-1">
                    <select
                      onChange={handleChange}
                      name="trainer"
                      className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                    >
                      <option disabled>Choose</option>
                      {props.commonData?.trainers?.map((x: any) => {
                        return (
                          <option
                            selected={x.id === video.trainer}
                            key={x.id}
                            value={x.id}
                          >
                            {x.title}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>

                <div>
                  <Label>Trainer Gender</Label>
                  <div className="mt-1">
                    <TextInput
                      readOnly
                      disabled
                      id="trainer_gender"
                      name="trainer_gender"
                      placeholder="Type"
                      type="text"
                      value={trainerGender}
                    />
                  </div>
                </div>

                <DropdownCheckbox
                  muscles={allMuscles}
                  value={selectedMuscles}
                  onChange={handleChange}
                  disabled={true}
                />

                <div>
                  <Label>Video Duration</Label>
                  <div className="mt-1">
                    <TextInput
                      id="video_duration"
                      name="video_duration"
                      placeholder="Video Duration"
                      onChange={handleChange}
                      value={video.video_duration}
                    />
                  </div>
                </div>

                <div>
                  <Label>Exercise Type</Label>
                  <div className="mt-1">
                    <select
                      onChange={(e) => setExerciseType(e.target.value)}
                      name="exercise"
                      className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                    >
                      <option selected disabled>
                        Choose
                      </option>
                      {props.commonData?.exerciseTypes.map((x: any) => {
                        return (
                          <option
                            key={x.id}
                            value={x.id}
                            selected={x.id === exerciseTypeId}
                          >
                            {x.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>

                <ExerciseInput
                  handleChange={handleChange}
                  exerciseTypeId={exerciseTypeId}
                  commonData={props?.commonData}
                  selected={video.exercise}
                />

                <RepsInput
                  exerciseTypeId={exerciseTypeId}
                  commonData={props?.commonData}
                  handleChange={handleChange}
                  value={video.rep_count}
                />

                <div>
                  <Label>Sets</Label>
                  <div className="mt-1">
                    <TextInput
                      id="set_count"
                      name="set_count"
                      placeholder="Sets"
                      onChange={handleChange}
                      type="number"
                      value={video.set_count}
                    />
                  </div>
                </div>

                <div>
                  <Label>Points</Label>
                  <div className="mt-1">
                    <TextInput
                      id="points"
                      name="points"
                      placeholder="Points"
                      onChange={handleChange}
                      type="number"
                      value={video.points}
                    />
                  </div>
                </div>

                <div>
                  <Label>Equipment</Label>
                  <div className="mt-1">
                    <select
                      onChange={handleChange}
                      name="equipment"
                      className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                    >
                      <option selected disabled>
                        Choose
                      </option>
                      {props.commonData?.equipments.map((x: any) => {
                        return (
                          <option
                            key={x.id}
                            value={x.id}
                            selected={x.id === video.equipment}
                          >
                            {x.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>

                <div>
                  <Label>Music Genre</Label>
                  <div className="mt-1">
                    <select
                      onChange={handleChange}
                      name="music_genre"
                      className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                    >
                      <option selected disabled>
                        Choose
                      </option>
                      {props.commonData?.musicGenres.map((x: any) => {
                        return (
                          <option
                            key={x.id}
                            value={x.id}
                            selected={x.id === video.music_genre}
                          >
                            {x.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>

                <div>
                  <Label>Music Artist</Label>
                  <div className="mt-1">
                    <TextInput
                      id="music_artist"
                      name="music_artist"
                      placeholder="Type"
                      type="string"
                      onChange={handleChange}
                      value={video.music_artist}
                    />
                  </div>
                </div>

                <div>
                  <Label>{"Workout Explaination Video (Optional)"}</Label>

                  <div className="mt-1">
                    <FileUploader
                      name="file"
                      handleChange={handleExplainedVideoFileChange}
                      types={["mp4", "MP4"]}
                    >
                      <div className="mt-1">
                        <div className="flex w-full items-center justify-center">
                          <label
                            htmlFor="dropzone-file"
                            className="flex h-64 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 hover:bg-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                          >
                            <div className="flex flex-col items-center justify-center pb-6 pt-5">
                              {explained_video ? (
                                <>
                                  <p className="text-black">
                                    {explained_video_name}
                                  </p>

                                  <p className="mt-3 text-xs text-gray-500 dark:text-gray-400">
                                    Click to replace
                                  </p>
                                </>
                              ) : (
                                <>
                                  <svg
                                    className="mb-4 size-8 text-gray-500 dark:text-gray-400"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 20 16"
                                  >
                                    <path
                                      stroke="currentColor"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                      d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                                    />
                                  </svg>
                                  <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                    <span className="font-semibold">
                                      Click to upload
                                    </span>{" "}
                                    or drag and drop
                                  </p>
                                  <p className="text-xs text-gray-500 dark:text-gray-400">
                                    MP4 File Allowed Only
                                  </p>
                                </>
                              )}
                            </div>
                          </label>
                        </div>
                      </div>
                    </FileUploader>
                  </div>

                  <div className="mt-3 text-[11px]">
                    <p>
                      Total Size: {explained_video_size} MB | Status:{" "}
                      {explained_video_progress
                        ? `In Progress ${explained_video_loaded} MB`
                        : "-"}
                    </p>
                  </div>
                </div>

                <div className="flex items-start"></div>
              </div>

              <div>
                <div className="mt-10">
                  <label className="relative inline-flex cursor-pointer items-center">
                    <input
                      type="checkbox"
                      name="published"
                      className="peer sr-only"
                      onChange={handleChange}
                      checked={video.published}
                    />
                    <div className="peer h-6 w-11 rounded-full bg-gray-200 after:absolute after:left-[2px] after:top-[2px] after:size-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-blue-800"></div>
                    <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                      Published
                    </span>
                  </label>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                color="primary"
                onClick={handleSubmit}
                disabled={isSubmitDisabled}
              >
                {isSubmitting ? "Updating..." : "Update"}
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div ref={rootRef}>
          <Modal
            onClose={() => props.onClose()}
            show={true}
            size="3xl"
            position="top-center"
            root={rootRef.current ?? undefined}
          >
            <Modal.Header className="border-b border-gray-200 !p-6 dark:border-gray-700">
              <strong>Edit Video</strong>
            </Modal.Header>
            <Modal.Body className="flex h-64 items-center justify-center">
              <div role="status">
                <svg
                  aria-hidden="true"
                  className="size-8 animate-spin fill-blue-600 text-gray-200 dark:text-gray-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
                <span className="sr-only">Loading...</span>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                color="primary"
                onClick={handleSubmit}
                disabled={isSubmitDisabled}
              >
                {isSubmitting ? "Updating..." : "Update"}
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </>
    );
  }
};

export default EditVideoModal;
