/* eslint-disable tailwindcss/no-custom-classname */
import { useEffect, useMemo, useRef, useState } from "react";
import {
  createNewWorkout,
  getWorkoutCategories,
} from "../../services/workouts";
import { toast } from "react-toastify";
import { Button, Label, Modal, TextInput, Textarea } from "flowbite-react";
import { HiPlus } from "react-icons/hi";
import { FileUploader } from "react-drag-drop-files";

import { difficulty } from "./data.json";
import { IntensityLevel } from "./EditWorkoutModal";

const AddWorkoutModal = function (props: any) {
  const rootRef = useRef<HTMLDivElement>(null);

  const [isOpen, setOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [workout, setWorkout]: any = useState({
    title: "",
    description: "",
    category: null,
    difficulty: null,
    trainer: null,
    rounds: null,
    published: false,
    featured: false,
  });

  const fileTypes = ["JPG", "PNG", "JPEG"];

  const [categories, setCategories] = useState([]);
  const [image, setImage]: any = useState(null);
  const [imageName, setImageName] = useState(null);
  const [imageType, setImageType] = useState(null);

  const [imageMobile, setImageMobile]: any = useState(null);
  const [imageNameMobile, setImageNameMobile] = useState(null);
  const [imageTypeMobile, setImageTypeMobile] = useState(null);

  const [previewUrlMobile, setPreviewUrlMobile] = useState(null);

  const [previewUrl, setPreviewUrl] = useState(null);

  const formValid = useMemo(() => {
    return (
      workout.title.trim() !== "" &&
      workout.description.trim() !== "" &&
      workout.category != null &&
      workout.difficulty != null &&
      workout.trainer != null &&
      workout.rounds != null &&
      image != null &&
      imageMobile != null
    );
  }, [workout, image, imageMobile]);

  const intensityArray = [
    { id: "Auto Assign", title: "Auto Assign" },
    ...Object.entries(IntensityLevel).map(([key, value]) => ({
      id: value,
      title: key,
    })),
  ];

  const handleFileChange = (file: any) => {
    const img = new Image();
    img.src = window.URL.createObjectURL(file);
    img.onload = () => {
      // @ts-expect-error:next-line

      setPreviewUrl(URL.createObjectURL(file));

      setImageName(file.name);

      setImageType(file.type);

      const reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => {
        setImage(reader.result);
      };
    };
  };

  const handleFileChangeMobile = (file: any) => {
    const img = new Image();
    img.src = window.URL.createObjectURL(file);
    img.onload = () => {
      // @ts-expect-error:next-line

      setPreviewUrlMobile(URL.createObjectURL(file));

      setImageNameMobile(file.name);

      setImageTypeMobile(file.type);

      const reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => {
        console.log(reader.result);

        setImageMobile(reader.result);
      };
    };
  };

  const handleChange = (e: any) => {
    const { name, value, checked, type } = e.target;
    setWorkout((prev: any) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);

      const new_workout = await createNewWorkout({
        ...workout,
        cover_image: image,
        cover_image_mobile: imageMobile,
        image_name: imageName,
        image_type: imageType,
        image_name_mobile: imageNameMobile,
        image_type_mobile: imageTypeMobile,
      });

      if (new_workout.data.error) {
        toast.error(
          `Unable to create new workout: ${new_workout.data.message}`,
        );
      } else {
        props.getWorkouts();
        setOpen(false);
        toast.success("New workout created successfully!");
      }
    } catch (e: any) {
      const reqMessage = e?.response?.data?.message;
      const message = Array.isArray(reqMessage)
        ? reqMessage.join(", ")
        : reqMessage;
      toast.error(`Unable to create new workout: ${message || e.message}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await getWorkoutCategories();
      setCategories(response.data);
    } catch {
      toast.error("Failed to load workout categories");
    }
  };

  useEffect(() => {
    if (isOpen) {
      fetchCategories();
    }
  }, [isOpen]);

  return (
    <>
      <div ref={rootRef}>
        <Button color="primary" onClick={() => setOpen(true)}>
          <div className="flex items-center gap-x-3">
            <HiPlus className="text-xl" />
            Add Workout
          </div>
        </Button>
        <Modal
          onClose={() => setOpen(false)}
          show={isOpen}
          size="3xl"
          position="top-center"
          root={rootRef.current ?? undefined}
        >
          <Modal.Header className="border-b border-gray-200 !p-6 dark:border-gray-700">
            <strong>Add New Workout</strong>
          </Modal.Header>
          <Modal.Body>
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
              <div>
                <Label htmlFor="firstName">Title</Label>
                <div className="mt-1">
                  <TextInput
                    name="title"
                    placeholder="Title"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div>
                <Label htmlFor="lastName">Description</Label>
                <div className="mt-1">
                  <Textarea
                    id="description"
                    name="description"
                    placeholder="description"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div>
                <Label>Category</Label>
                <div className="mt-1">
                  <select
                    onChange={handleChange}
                    name="category"
                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                  >
                    <option selected disabled>
                      Choose
                    </option>
                    {categories.map((category: any) => (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div>
                <Label>Difficulty</Label>
                <div className="mt-1">
                  <select
                    onChange={handleChange}
                    name="difficulty"
                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                  >
                    <option selected disabled>
                      Choose
                    </option>
                    {difficulty.map((x: any, i: any) => {
                      return (
                        <>
                          <option value={i}>{x}</option>
                        </>
                      );
                    })}
                  </select>
                </div>
              </div>

              <div>
                <Label>Select Athlete</Label>
                <div className="mt-1">
                  <select
                    onChange={handleChange}
                    name="trainer"
                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                  >
                    <option selected disabled>
                      Choose
                    </option>
                    {props.trainers.map((x: any) => {
                      return (
                        <>
                          <option value={x.id}>{x.title}</option>
                        </>
                      );
                    })}
                  </select>
                </div>
              </div>

              <div>
                <Label>Select Intensity</Label>
                <div className="mt-1">
                  <select
                    onChange={handleChange}
                    name="intensity"
                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                  >
                    <option selected disabled>
                      Choose
                    </option>
                    {intensityArray.map((item, i) => {
                      return (
                        <option
                          key={i}
                          value={item.id}
                          selected={item.id === "Auto Assign"}
                        >
                          {item.title}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>

              <div>
                <Label>Rounds</Label>
                <div className="mt-1">
                  <select
                    name="rounds"
                    onChange={handleChange}
                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                  >
                    <option selected disabled>
                      Choose
                    </option>
                    <option value="1">x1</option>
                    <option value="2">x2</option>
                    <option value="3">x3</option>
                    <option value="4">x4</option>
                    <option value="5">x5</option>
                    <option value="6">x6</option>
                    <option value="7">x7</option>
                  </select>
                </div>
              </div>

              {/* Empty space */}
              <div></div>

              <div>
                <Label>Cover Image</Label>
                <FileUploader
                  name="file"
                  handleChange={handleFileChange}
                  types={fileTypes}
                >
                  <div className="mt-1">
                    <div className="flex w-full items-center justify-center">
                      <label
                        htmlFor="dropzone-file"
                        className="dark:hover:bg-bray-800 flex h-64 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 hover:bg-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                      >
                        <div className="flex flex-col items-center justify-center pb-6 pt-5">
                          {previewUrl ? (
                            <>
                              <img
                                src={previewUrl}
                                alt=""
                                width={150}
                                height={150}
                              />

                              <p className="mt-3 text-xs text-gray-500 dark:text-gray-400">
                                Click to replace
                              </p>
                            </>
                          ) : (
                            <>
                              <svg
                                className="mb-4 size-8 text-gray-500 dark:text-gray-400"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 20 16"
                              >
                                <path
                                  stroke="currentColor"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                                />
                              </svg>
                              <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                <span className="font-semibold">
                                  Click to upload
                                </span>{" "}
                                or drag and drop
                              </p>
                              <p className="text-xs text-gray-500 dark:text-gray-400">
                                PNG, JPG (MAX. 800x400px)
                              </p>
                            </>
                          )}
                        </div>
                      </label>
                    </div>
                  </div>
                </FileUploader>
              </div>

              <div>
                <Label>Cover Image Mobile</Label>
                <FileUploader
                  name="file"
                  handleChange={handleFileChangeMobile}
                  types={fileTypes}
                >
                  <div className="mt-1">
                    <div className="flex w-full items-center justify-center">
                      <label
                        htmlFor="dropzone-file"
                        className="dark:hover:bg-bray-800 flex h-64 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 hover:bg-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                      >
                        {previewUrlMobile ? (
                          <>
                            <img
                              src={previewUrlMobile}
                              alt=""
                              width={150}
                              height={150}
                            />

                            <p className="mt-3 text-xs text-gray-500 dark:text-gray-400">
                              Click to replace
                            </p>
                          </>
                        ) : (
                          <>
                            <svg
                              className="mb-4 size-8 text-gray-500 dark:text-gray-400"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 20 16"
                            >
                              <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                              />
                            </svg>
                            <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                              <span className="font-semibold">
                                Click to upload
                              </span>{" "}
                              or drag and drop
                            </p>
                            <p className="text-xs text-gray-500 dark:text-gray-400">
                              PNG, JPG (MAX. 800x400px)
                            </p>
                          </>
                        )}
                      </label>
                    </div>
                  </div>
                </FileUploader>
              </div>

              <div className="flex items-start"></div>
            </div>

            <div>
              <div className="mt-10">
                <label className="relative inline-flex cursor-pointer items-center">
                  <input
                    type="checkbox"
                    name="published"
                    className="peer sr-only"
                    onChange={handleChange}
                  />
                  <div className="peer h-6 w-11 rounded-full bg-gray-200 after:absolute after:left-[2px] after:top-[2px] after:size-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-blue-800"></div>
                  <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                    Published
                  </span>
                </label>
              </div>
            </div>

            <div>
              <div className="mt-10">
                <label className="relative inline-flex cursor-pointer items-center">
                  <input
                    type="checkbox"
                    name="featured"
                    className="peer sr-only"
                    onChange={handleChange}
                  />
                  <div className="peer h-6 w-11 rounded-full bg-gray-200 after:absolute after:left-[2px] after:top-[2px] after:size-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-blue-800"></div>
                  <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                    Featured
                  </span>
                </label>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              color="primary"
              onClick={handleSubmit}
              disabled={isSubmitting || !formValid}
            >
              {isSubmitting ? "Creating..." : "Create"}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default AddWorkoutModal;
