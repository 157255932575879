/* eslint-disable tailwindcss/no-custom-classname */
import {
  Button,
  FileInput,
  Label,
  Modal,
  TextInput,
  Textarea,
} from "flowbite-react";
import { useEffect, useRef, useState } from "react";
import { getAllWorkouts } from "../../services/workouts";
import { programExercises, updateProgram } from "../../services/programs";
import { toast } from "react-toastify";
import { HiPlus } from "react-icons/hi";
import { FileUploader } from "react-drag-drop-files";
import AsyncSelect from "react-select/async";
import { ProgramDifficultyOptions } from "./list";

const EditProgramModal = function (props: any) {
  const [isOpen, setOpen] = useState(false);
  const [update, setUpdate] = useState(false);

  const [days, setDays]: any = useState([]);
  const [day, setDay]: any = useState(0);

  const [program, setProgram]: any = useState({});

  const fileTypes = ["JPG", "PNG", "JPEG"];

  const [image, setImage]: any = useState(null);
  const [imageName, setImageName]: any = useState(null);
  const [imageType, setImageType]: any = useState(null);

  const [name, setName] = useState("");
  const [selectedFile, setSelectedFile]: any = useState(null);

  const rootRef = useRef<HTMLDivElement>(null);

  const [previewUrl, setPreviewUrl] = useState(null);
  const [touchedFields, setTouchedFields] = useState<string[]>([]);

  useEffect(() => {
    console.log("program ", program);

    console.log("props.trainers", props.trainers);
  }, [program, props.trainers]);

  useEffect(() => {
    const init = async () => {
      const exercises = await programExercises(props.program.id);

      const exercises_data = exercises.data.results;

      const _days = exercises_data.map((x: any) => {
        return {
          day: x.workout_day,
          workout: x.workout_id,
          title: x.workout_title,
        };
      });

      setDays(_days);

      setDay(props.program.program_duration);
      setProgram(props.program);
    };

    init();
  }, [props.program]);

  const loadData = async (query: string) => {
    const response = await getAllWorkouts({
      page: 1,
      take: 20,
      search: query,
      dropdown: true,
    });

    const data = response.data.results.workouts;

    return data;
  };

  const handleFileInput = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      setName(file.name);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setSelectedFile({
          fileData: reader.result,
          fileName: file.name,
        });
      };
    }
  };

  const handleFileChange = (file: any) => {
    const img = new Image();
    img.src = window.URL.createObjectURL(file);
    img.onload = () => {
      // @ts-expect-error:next-line

      setPreviewUrl(URL.createObjectURL(file));

      setImageName(file.name);

      setImageType(file.type);

      const reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => {
        setImage(reader.result);
      };
    };
  };

  const handleChange = (e: any) => {
    const { name, value, checked } = e.target;
    setProgram((prev: any) => ({
      ...prev,
      [name]: name === "published" ? checked : value,
    }));

    if (name === "program_duration") {
      const newDuration = parseInt(value);
      setDay(newDuration);
      setDays((prevDays: any[]) => {
        return prevDays.filter((dayEntry) => dayEntry.day <= newDuration);
      });
    }

    if (!touchedFields.includes(name)) {
      setTouchedFields((prevFields) => [...prevFields, name]);
    }
  };

  const handleSubmit = async () => {
    setUpdate(true);

    const updatedFields: any = {};
    touchedFields.forEach((field: any) => {
      updatedFields[field] = program[field];
    });

    const update_program = await updateProgram(
      {
        ...updatedFields,
        ...(image ? { cover_image: image } : {}),
        ...(imageName ? { image_name: imageName } : {}),
        ...(imageType ? { image_type: imageType } : {}),
        ...(days ? { days } : {}),
        ...(name ? { name } : {}),
        ...(selectedFile ? { file: selectedFile } : {}),
      },
      props.program.id,
    );

    if (update_program.data.error) {
      toast.error(update_program.data.message);
    } else {
      props.getPrograms();
      setOpen(false);
      toast.success("Program updated successfully!");
    }

    setUpdate(false);
  };

  useEffect(() => {
    console.log("selectedFile ", selectedFile);
  }, [selectedFile]);

  return (
    <>
      <Button color="primary" onClick={() => setOpen(true)}>
        <div className="flex items-center gap-x-3">
          <HiPlus className="text-xl" />
          Edit Program
        </div>
      </Button>
      <div ref={rootRef}>
        <Modal
          onClose={() => setOpen(false)}
          show={isOpen}
          size="7xl"
          position="top-center"
          root={rootRef.current ?? undefined}
        >
          <Modal.Header className="border-b border-gray-200 !p-6 dark:border-gray-700">
            <strong>Edit Program</strong>
          </Modal.Header>
          <Modal.Body>
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
              <div>
                <Label htmlFor="firstName">Title</Label>
                <div className="mt-1">
                  <TextInput
                    id="title"
                    name="title"
                    defaultValue={props.program.title}
                    placeholder="Title"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div>
                <Label htmlFor="lastName">Description</Label>
                <div className="mt-1">
                  <Textarea
                    id="description"
                    name="description"
                    defaultValue={props.program.description}
                    placeholder="description"
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div>
                <Label htmlFor="lastName">Program Duration</Label>
                <div className="mt-1">
                  <TextInput
                    id="program_duration"
                    name="program_duration"
                    placeholder="Program Duration"
                    type="number"
                    defaultValue={props.program.program_duration}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div>
                <Label htmlFor="program_difficulty">Difficulty</Label>
                <div className="mt-1">
                  <select
                    id="program_difficulty"
                    name="program_difficulty"
                    onChange={handleChange}
                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                  >
                    <option value="" disabled>
                      Choose Difficulty
                    </option>
                    {ProgramDifficultyOptions.map((option) => (
                      <option
                        key={option.value}
                        value={option.value}
                        selected={option.value === program.program_difficulty}
                      >
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div>
                <Label>Select Athlete</Label>
                <div className="mt-1">
                  <select
                    onChange={handleChange}
                    name="trainer"
                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                  >
                    <option selected disabled>
                      Choose
                    </option>
                    {props.trainers.map((option: any) => (
                      <option
                        key={option.id}
                        value={option.id}
                        selected={option.id === program.trainer_id}
                      >
                        {option.title}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div>
                <Label htmlFor="nutritionFile">Nutrition Plan</Label>
                <div className="mt-1 flex flex-row items-center gap-2">
                  <div className="w-64">
                    <FileInput
                      id="nutritionFile"
                      onChange={(e: any) => handleFileInput(e)}
                    />
                  </div>
                  {props.program.nutrition_pdf && (
                    <a
                      href={props.program.nutrition_pdf}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex items-center justify-center rounded-md border border-gray-300 p-2 text-center align-middle leading-none hover:bg-gray-200"
                      style={{ height: "100%", padding: "13px" }}
                    >
                      View current PDF
                    </a>
                  )}
                </div>
              </div>

              <div>
                <Label>Cover Image</Label>
                <FileUploader
                  name="file"
                  handleChange={handleFileChange}
                  types={fileTypes}
                >
                  <div className="mt-1">
                    <div className="flex w-full items-center justify-center">
                      <label
                        htmlFor="dropzone-file"
                        className="dark:hover:bg-bray-800 flex h-64 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 hover:bg-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                      >
                        <div className=" flex flex-col items-center justify-center rounded pb-6 pt-5">
                          {props.program.cover_image ? (
                            <>
                              {previewUrl ? (
                                <>
                                  <img
                                    className="cover-image"
                                    src={previewUrl}
                                    alt=""
                                    width={150}
                                    height={150}
                                    onError={(e: any) => {
                                      e.target.onError = null;
                                      e.target.src =
                                        "https://content.sweatsonic.com/programs-placeholder";
                                    }}
                                  />

                                  <p className="mt-3 text-xs text-gray-500 dark:text-gray-400">
                                    Click to replace{" "}
                                  </p>
                                </>
                              ) : (
                                <>
                                  <img
                                    className="cover-image"
                                    src={props.program.cover_image}
                                    alt=""
                                    width={300}
                                    height={300}
                                  />

                                  <p className="mt-3 text-xs text-gray-500 dark:text-gray-400">
                                    Click to replace
                                  </p>
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              {previewUrl ? (
                                <>
                                  <img
                                    className="cover-image"
                                    src={previewUrl}
                                    alt=""
                                    width={150}
                                    height={150}
                                  />

                                  <p className="mt-3 text-xs text-gray-500 dark:text-gray-400">
                                    Click to replace
                                  </p>
                                </>
                              ) : (
                                <>
                                  <svg
                                    className="mb-4 size-8 text-gray-500 dark:text-gray-400"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 20 16"
                                  >
                                    <path
                                      stroke="currentColor"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                      d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                                    />
                                  </svg>
                                  <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                    <span className="font-semibold">
                                      Click to upload
                                    </span>{" "}
                                    or drag and drop
                                  </p>
                                  <p className="text-xs text-gray-500 dark:text-gray-400">
                                    PNG, JPG (MAX. 800x400px)
                                  </p>
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </label>
                    </div>
                  </div>
                </FileUploader>
              </div>

              <div></div>

              {day > 0 &&
                Array(day)
                  .fill({})
                  .map((d: any, i: any) => {
                    const _e: any = days.filter((x: any) => x.day === i + 1);
                    return (
                      <>
                        <div className="mt-2 max-w-md">
                          <Label>Workout Day</Label>

                          <TextInput
                            id={d}
                            name="day"
                            disabled={true}
                            defaultValue={i + 1}
                          />
                        </div>

                        <div className="max-w-md">
                          <Label> Choose Workout </Label>
                          <AsyncSelect
                            isClearable={true}
                            className="mt-2"
                            value={
                              _e?.length
                                ? { label: _e[0]?.title, value: _e[0]?.value }
                                : { label: "Search...", value: 0 }
                            }
                            loadOptions={loadData}
                            onInputChange={loadData}
                            noOptionsMessage={() => "No results"}
                            onChange={(selectedOption: any) => {
                              const selectedDay = i + 1;
                              const newWorkoutValue = selectedOption
                                ? selectedOption.value
                                : null;

                              const newWorkoutTitle = selectedOption
                                ? selectedOption.label
                                : null;

                              setDays((prevDays: any) => {
                                const existingDayIndex = prevDays.findIndex(
                                  (x: { day: any }) => x.day === selectedDay,
                                );
                                if (existingDayIndex !== -1) {
                                  return prevDays.map(
                                    (item: any, index: any) => {
                                      if (index === existingDayIndex) {
                                        return {
                                          ...item,
                                          workout: newWorkoutValue,
                                          title: newWorkoutTitle,
                                        };
                                      }
                                      return item;
                                    },
                                  );
                                } else {
                                  return [
                                    ...prevDays,
                                    {
                                      day: selectedDay,
                                      workout: newWorkoutValue,
                                      title: newWorkoutTitle,
                                    },
                                  ];
                                }
                              });
                            }}
                          />
                        </div>
                      </>
                    );
                  })}
            </div>

            <div className="mt-10">
              <label className="relative inline-flex cursor-pointer items-center">
                <input
                  type="checkbox"
                  name="featured"
                  className="peer sr-only"
                  onChange={handleChange}
                />
                <div className="peer h-6 w-11 rounded-full bg-gray-200 after:absolute after:left-[2px] after:top-[2px] after:size-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-blue-800"></div>
                <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                  Featured
                </span>
              </label>
            </div>

            <div>
              <div className="mt-10">
                <label className="relative inline-flex cursor-pointer items-center">
                  <input
                    type="checkbox"
                    name="published"
                    className="peer sr-only"
                    onChange={handleChange}
                    defaultChecked={props.program.published}
                  />
                  <div className="peer h-6 w-11 rounded-full bg-gray-200 after:absolute after:left-[2px] after:top-[2px] after:size-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-blue-800"></div>
                  <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                    Published
                  </span>
                </label>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button color="primary" onClick={handleSubmit} disabled={update}>
              {update ? "Updating..." : "Update"}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default EditProgramModal;
