/* eslint-disable jsx-a11y/img-redundant-alt */
import { Pagination, Table } from "flowbite-react";
import EditTrainerModal from "./EditTrainerModal";
import DeleteTrainerModal from "./DeleteTrainerModal";
import { useSelector } from "react-redux";
import { CONTENT_PER_PAGE } from "./list";

const AllTrainersTable = function (props: any) {
  const { count } = useSelector((state: any) => state.trainers);

  return (
    <div className="mb-8">
      <Table className="min-w-full divide-y divide-gray-200 dark:divide-gray-600">
        <Table.Head className="bg-gray-100 dark:bg-gray-700">
          <Table.HeadCell></Table.HeadCell>
          <Table.HeadCell>Name</Table.HeadCell>
          <Table.HeadCell>Status</Table.HeadCell>
          <Table.HeadCell>Actions</Table.HeadCell>
        </Table.Head>
        <Table.Body className="divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-800">
          {props.trainers?.length ? (
            <>
              {props.trainers?.map((trainer: any) => {
                return (
                  <>
                    <Table.Row className="hover:bg-gray-100 dark:hover:bg-gray-700">
                      <Table.Cell className="mr-12 flex items-center space-x-6 whitespace-nowrap p-4 lg:mr-0">
                        <img
                          className="size-10 rounded-full"
                          src={trainer.profile_image}
                          alt="profile image"
                          onError={(e: any) => {
                            e.target.onError = null;
                            e.target.src =
                              "https://content.sweatsonic.com/1710419465.176-59073b9c-f095-491f-b927-e31625da7aab";
                          }}
                        />
                        <div className="text-sm font-normal text-gray-500 dark:text-gray-400">
                          <div className="text-base font-semibold text-gray-900 dark:text-white">
                            {trainer.title}
                          </div>
                          <div className="text-sm font-normal text-gray-500 dark:text-gray-400">
                            @{trainer.username}
                          </div>
                        </div>
                      </Table.Cell>
                      <Table.Cell className="whitespace-nowrap p-4 text-base font-medium text-gray-900 dark:text-white">
                        {trainer.title}
                      </Table.Cell>

                      <Table.Cell className="whitespace-nowrap p-4 text-base font-normal text-gray-900 dark:text-white">
                        <div className="flex items-center">
                          <div
                            className={`mr-2 size-2.5 rounded-full ${
                              trainer.status ? "bg-green-400" : "bg-red-400"
                            }`}
                          ></div>{" "}
                          {trainer.status ? "Active" : "Not Active"}
                        </div>
                      </Table.Cell>
                      <Table.Cell>
                        <div className="flex items-center gap-x-3 whitespace-nowrap">
                          <EditTrainerModal
                            trainer={trainer}
                            getTrainers={props.getTrainers}
                          />
                          <DeleteTrainerModal
                            trainer={trainer}
                            getTrainers={props.getTrainers}
                          />
                        </div>
                      </Table.Cell>
                    </Table.Row>
                  </>
                );
              })}
            </>
          ) : (
            <></>
          )}
        </Table.Body>
        <Pagination
          currentPage={props.currentPage}
          onPageChange={(page) => {
            props.setCurrentPage(page);
          }}
          showIcons
          aria-disabled={true}
          totalPages={Math.ceil(Number(count || 1) / CONTENT_PER_PAGE)}
        />
      </Table>
    </div>
  );
};

export default AllTrainersTable;
