import { ActionType } from "../actionTypes/index";

const initialState = {
  trainers: [],
  loading: false, // Added loading state to the initial state
  event: null,
};

const trainersReducer = (state = initialState, action: any): any => {
  console.log("=== Reducer Trainers ===");
  console.log(action);
  switch (action.type) {
    case ActionType.TRAINERS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case ActionType.GET_ALL_TRAINERS:
      return {
        ...state,
        trainers: action.payload.data.results.trainers,
        count: action.payload.data.results.total,
        event: action.type,
        loading: false,
      };
    case ActionType.GET_ALL_TRAINERS_FAILED:
      return {
        ...state,
        event: action.type,
        loading: false,
      };
    case ActionType.TRAINERS_LOADING_DONE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default trainersReducer;
