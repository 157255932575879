/* eslint-disable @typescript-eslint/prefer-ts-expect-error */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable tailwindcss/no-custom-classname */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */

import { Breadcrumb, Label, Select } from "flowbite-react";
import type { FC } from "react";
import { useEffect, useState } from "react";
import { HiHome, HiXCircle } from "react-icons/hi";
import NavbarSidebarLayout from "../../layouts/navbar-sidebar";
import { useDispatch, useSelector } from "react-redux";
import ActionType from "../../redux/actionTypes";
import {
  featuredTrainer,
  getAllTrainers,
  getFeaturedTrainers,
} from "../../services/trainers";
import { getPrograms } from "../../redux/actionCreators/programs";
import AsyncSelect from "react-select/async";
import {
  changeDailyWorkout,
  featuredWorkout,
  getAllWorkouts,
  getDailyWorkouts,
  getFeaturedWorkouts,
} from "../../services/workouts";
import { featuredProgram, getFeaturedPrograms } from "../../services/programs";
import FeaturedInstructionVideos from "./FeaturedVideos";
import * as videosAPI from "../../services/videos";
import { ToastContainer, toast } from "react-toastify";

interface RootState {
  auth: any;
  users: any;
  workouts: any;
  programs: any;
}

const Spotlight: FC = function () {
  const dispatch: any = useDispatch();
  const state: any = useSelector((state: RootState) => state);

  const [programs, setPrograms]: any = useState([]);
  const [trainers, setTrainers]: any = useState([]);
  const [instructionalVideos, setInstructionalVideos]: any = useState([]);
  const [loading, setIsLoading] = useState(false);

  const [programs_featured, setFeaturedPrograms]: any = useState([]);
  const [trainers_featured, setFeaturedTrainers] = useState([]);
  const [workouts_featured, setFeaturedWokrouts]: any = useState([]);
  const [workout_daily, setWorkoutDaily]: any = useState([]);
  const [instruction_videos_featured, setFeaturedInstructionVideos] = useState(
    [],
  );

  const loadData = async (query: string) => {
    console.log("Search : ", query);

    const response = await getAllWorkouts({
      page: 1,
      take: 20,
      search: query,
      dropdown: true,
    });
    console.log("=== DropDown ===");

    const data = response.data.results.workouts;

    console.log(data);

    // setOptions(data);

    return data;
  };

  useEffect(() => {
    const init = async () => {
      const _trainers = await getAllTrainers({});

      console.log("==== Trainers ====");

      setTrainers(_trainers?.data?.results.trainers);

      const _fp = await getFeaturedPrograms();

      setFeaturedPrograms(_fp.data.results);

      const _ft = await getFeaturedTrainers();

      setFeaturedTrainers(_ft.data.results);

      const _fw = await getFeaturedWorkouts();

      setFeaturedWokrouts(_fw.data.results);

      const dailyWorkouts = await getDailyWorkouts();

      setWorkoutDaily(dailyWorkouts);

      const _fv = await videosAPI.getFeaturedVideos();

      setFeaturedInstructionVideos(_fv.data.results);

      const instructionalVideosReq =
        await videosAPI.getAllInstructionalVideos();

      setInstructionalVideos(instructionalVideosReq.data.results.videos);
    };

    init();
  }, []);

  const getProgramData = () => {
    setIsLoading(true);
    dispatch(
      getPrograms({
        page: 1,
        take: 50,
      }),
    );
  };

  useEffect(() => {
    getProgramData();
  }, []);

  useEffect(() => {
    const init = async () => {
      const trainers: any = await getAllTrainers({});

      console.log("=== Trainers ===");

      console.log(trainers);

      setTrainers(trainers.data.results.trainers);
    };

    init();
  }, []);

  useEffect(() => {
    if (state.programs.event === ActionType.GET_ALL_PROGRAMS) {
      console.log("=== PROGRAMS ===", loading);
      setIsLoading(false);
      console.log(state.programs.programs);
      setPrograms(state.programs.programs);
    } else if (state?.programs?.type == ActionType.GET_ALL_PROGRAMS_FAILED) {
      console.log("Error : unable to fetch programs");
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  console.log("==== Programs ====");

  console.log(programs);

  return (
    <>
      <NavbarSidebarLayout isFooter={false}>
        <div className="block items-start justify-between border-b border-gray-200 bg-white p-4 dark:border-gray-700 dark:bg-gray-800 sm:flex">
          <div className="mb-1 w-full">
            <div className="mb-4">
              <Breadcrumb className="mb-4">
                <Breadcrumb.Item href="#">
                  <div className="flex items-center gap-x-3">
                    <HiHome className="text-xl" />
                    <span className="dark:text-white">Home</span>
                  </div>
                </Breadcrumb.Item>
                <Breadcrumb.Item href="/spotlight">Spotlight</Breadcrumb.Item>
              </Breadcrumb>
              <h1 className="text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl">
                Spotlight
              </h1>
            </div>
            <div className="mt-3 w-full">
              <div className="max-w-md" id="select">
                <div className="mb-2 block">
                  <Label value="Choose athletes to feature" />
                </div>
                <Select
                  onChange={async (e) => {
                    const selected: any = trainers
                      .filter((x: any) => x.id == e.target.value)
                      .shift();

                    if (selected) {
                      console.log("==== Selected traner ====");

                      console.log(selected);

                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore:next-line

                      setFeaturedTrainers([...trainers_featured, selected]);

                      await featuredTrainer(
                        {
                          featured: true,
                        },
                        e.target.value,
                      );
                    }
                  }}
                >
                  <option selected disabled>
                    Select
                  </option>

                  {trainers?.map((x: any) => {
                    return (
                      <>
                        <option value={x?.id}>{x?.title}</option>
                      </>
                    );
                  })}
                </Select>
              </div>

              <div className="my-3 mt-10 flex flex-wrap">
                {trainers_featured?.map((x: any, i: any) => {
                  return (
                    <>
                      <span className="m-1 flex cursor-pointer flex-wrap items-center justify-between rounded bg-gray-200 px-4 py-2 text-xs font-bold leading-loose hover:bg-gray-300 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-600 sm:text-sm">
                        {x.title} &nbsp;
                        <span
                          onClick={async () => {
                            const data = trainers_featured.filter(
                              (t: any) => t.id !== x.id,
                            );
                            setFeaturedTrainers(data);

                            await featuredTrainer(
                              {
                                featured: false,
                              },
                              x.id,
                            );
                          }}
                          className="inline-flex cursor-pointer  text-red-700"
                        >
                          <HiXCircle className="relative top-0 size-4" />
                        </span>
                      </span>

                      {i === 4 && <br />}
                    </>
                  );
                })}
              </div>

              <div className="mt-10 max-w-md" id="select">
                <div className="mb-2 block">
                  <Label value="Choose Programs to feature" />
                </div>
                {loading ? (
                  <>
                    <p>Loading...</p>
                  </>
                ) : (
                  <>
                    <Select
                      onChange={async (e) => {
                        const selected: any = programs?.data
                          ?.filter((x: any) => x.id == e.target.value)
                          .shift();

                        if (selected) {
                          console.log(selected);

                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore:next-line

                          setFeaturedPrograms([...programs_featured, selected]);

                          await featuredProgram(
                            {
                              featured: true,
                            },
                            e.target.value,
                          );
                        }
                      }}
                    >
                      <option selected disabled>
                        Select
                      </option>

                      {programs.data?.map((x: any) => {
                        return (
                          <>
                            <option value={x?.id}>{x?.title}</option>
                          </>
                        );
                      })}
                    </Select>
                  </>
                )}
              </div>

              <div className="my-3 mt-10 flex flex-wrap">
                {programs_featured?.map((x: any, i: any) => {
                  return (
                    <>
                      <span className="m-1 flex cursor-pointer flex-wrap items-center justify-between rounded bg-gray-200 px-4 py-2 text-xs font-bold leading-loose hover:bg-gray-300 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-600 sm:text-sm">
                        {x.title} &nbsp;
                        <span
                          onClick={async () => {
                            const data = programs_featured.filter(
                              (p: any) => p.id !== x.id,
                            );
                            setFeaturedPrograms(data);

                            await featuredProgram(
                              {
                                featured: false,
                              },
                              x.id,
                            );
                          }}
                          className="inline-flex cursor-pointer  text-red-700"
                        >
                          <HiXCircle className="relative top-0 size-4" />
                        </span>
                      </span>

                      {i === 4 && <br />}
                    </>
                  );
                })}
              </div>

              <div className="mt-10 max-w-md">
                <div className="mb-2 block">
                  <Label> Choose Daily Workout </Label>
                </div>
                <AsyncSelect
                  isClearable={true}
                  className=""
                  defaultValue={[]}
                  loadOptions={loadData}
                  noOptionsMessage={() => "No results"}
                  onChange={async (v: any) => {
                    if (v) {
                      setWorkoutDaily([
                        {
                          id: v.value,
                          title: v.label,
                        },
                      ]);
                      await changeDailyWorkout(v.value);
                      toast.success("Daily Workout updated successfully!");
                    }
                  }}
                />
              </div>
              <div className="-m-1 my-3 mt-10 flex flex-wrap">
                {workout_daily?.map((x: any, i: any) => {
                  return (
                    <>
                      <span className="m-1 flex cursor-pointer flex-wrap items-center justify-between rounded bg-gray-200 px-4 py-2 text-xs font-bold leading-loose hover:bg-gray-300 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-600 sm:text-sm">
                        {x.title} &nbsp;
                      </span>

                      {i === 4 && <br />}
                    </>
                  );
                })}
              </div>

              <div className="mt-10 max-w-md">
                <div className="mb-2 block">
                  <Label> Choose Workouts to feature </Label>
                </div>
                <AsyncSelect
                  isClearable={true}
                  className=""
                  defaultValue={[]}
                  loadOptions={loadData}
                  onInputChange={loadData}
                  noOptionsMessage={() => "No results"}
                  onChange={async (v: any) => {
                    console.log("=== Choose ===");
                    console.log(v);

                    const selected: any = workouts_featured
                      .filter((x: any) => x.id == v.value)
                      .shift();

                    if (selected) {
                      alert("Workout already selected");
                    } else {
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore:next-line

                      setFeaturedWokrouts([
                        ...workouts_featured,
                        {
                          id: v.value,
                          title: v.label,
                        },
                      ]);

                      await featuredWorkout(
                        {
                          featured: true,
                        },
                        v.value,
                      );
                    }
                  }}
                />
              </div>

              <div className="-m-1 my-3 mt-10 flex flex-wrap">
                {workouts_featured?.map((x: any, i: any) => {
                  return (
                    <>
                      <span className="m-1 flex cursor-pointer flex-wrap items-center justify-between rounded bg-gray-200 px-4 py-2 text-xs font-bold leading-loose hover:bg-gray-300 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-600 sm:text-sm">
                        {x.title} &nbsp;
                        <span
                          onClick={async () => {
                            const data = workouts_featured.filter(
                              (w: any) => w.id !== x.id,
                            );
                            setFeaturedWokrouts(data);
                            await featuredWorkout(
                              {
                                featured: false,
                              },
                              x.id,
                            );
                          }}
                          className="inline-flex cursor-pointer  text-red-700"
                        >
                          <HiXCircle className="relative top-0 size-4" />
                        </span>
                      </span>

                      {i === 4 && <br />}
                    </>
                  );
                })}
              </div>

              <FeaturedInstructionVideos
                loading={loading}
                videosAPI={videosAPI}
                instruction_videos_featured={instruction_videos_featured}
                setFeaturedInstructionVideos={setFeaturedInstructionVideos}
                instructionalVideos={instructionalVideos}
              />
            </div>
          </div>
        </div>
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          rtl={false}
          pauseOnFocusLoss
          pauseOnHover
          theme="dark"
        />
      </NavbarSidebarLayout>
    </>
  );
};

export default Spotlight;
