import axios from "axios";
import { Button, Label, Modal, TextInput, Textarea } from "flowbite-react";
import { useEffect, useMemo, useRef, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { HiPlus } from "react-icons/hi";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import config from "../../config/config";
import { createNewVideo } from "../../services/videos";
import DropdownCheckbox from "./DropdownCheckbox";
import ExerciseInput from "./ExerciseInput";
import RepsInput from "./RepsInput";

interface AddVideoModalProps {
  commonData: any;
  getVideos: () => void;
  trainers: any;
}

const AddVideoModal = function (props: AddVideoModalProps) {
  const rootRef = useRef<HTMLDivElement>(null);

  const [isOpen, setOpen] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [video, setVideo] = useState({
    workout_video: "",
    exercise_explained_video: "",
    trainer: "0",
    title: "",
    points: 0,
    rep_count: "0",
    set_count: "0",
    video_duration: "",
    brief: "",
    exercise: "",
    published: false,
    video_thumbnail: "",
    video_thumbnail_type: "",
    workout_video_name: "",
    workout_video_type: "",
    explained_video_name: "",
    explained_video_type: "",
    image_name: "",
    image_type: "",
  });

  const fileTypes = ["JPG", "PNG", "JPEG"];

  const [image, setImage] = useState("");
  const [image_name, setImageName] = useState("");
  const [image_type, setImageType] = useState("");

  const [previewUrl, setPreviewUrl] = useState("");

  const [workout_video, setWorkoutVideo] = useState<string>("");
  const [workout_video_name, setWorkoutVideoName] = useState("");
  const [workout_video_type, setWorkoutVideoType] = useState("");
  const [workout_video_size, setWorkoutVideoSize] = useState("");
  const [workout_video_progress, setWorkoutVideoProgress] = useState(false);
  const [workout_video_loaded, setWorkoutVideoLoaded] = useState("");

  const [explained_video, setExplainedVideo] = useState("");
  const [explained_video_name, setExplainedVideoName] = useState("");
  const [explained_video_type, setExplainedVideoType] = useState("");

  const [explained_video_size, setExplainedVideoSize] = useState("");
  const [explained_video_progress, setExplainedVideoProgress] = useState(false);
  const [explained_video_loaded, setExplainedVideoLoaded] = useState("");
  const [exerciseTypeId, setExerciseType] = useState("");

  const [allMuscles] = useState<any>(props?.commonData?.muscles);
  const [selectedMuscles, setSelectedMuscles] = useState();

  useEffect(() => {
    if (props.commonData && video.exercise) {
      const muscles = props.commonData.exercises.find(
        (e: any) => e.id === video.exercise,
      ).muscles;
      setSelectedMuscles(muscles);
    }
  }, [props.commonData, video.exercise]);

  const handleFileChange = (file: any) => {
    const img = new Image();
    img.src = window.URL.createObjectURL(file);
    img.onload = () => {
      setPreviewUrl(URL.createObjectURL(file));

      setImageName(file.name);

      setImageType(file.type);

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (typeof reader.result === "string") {
          setImage(reader.result);
        }
      };
    };
  };

  const handleWorkoutVideoFileChange = (file: any) => {
    if (file.type === "video/mp4") {
      const videoUrl = URL.createObjectURL(file);

      const videoElement = document.createElement("video");
      videoElement.src = videoUrl;

      videoElement.addEventListener("loadedmetadata", () => {
        handleChange({
          target: {
            name: "video_duration",
            value: String(Math.floor(videoElement.duration)),
          },
        });
        URL.revokeObjectURL(videoUrl);
      });
    } else {
      alert("Please upload a valid MP4 video file.");
      return;
    }

    setWorkoutVideoName(file.name);

    setWorkoutVideoType(file.type);

    const date = new Date();
    const seconds = date.getTime() / 1000;
    const file_name = `${seconds}-${uuidv4()}`;

    setWorkoutVideo(file_name);

    setWorkoutVideoSize((file.size / (1024 * 1024)).toFixed(2));

    const formData = new FormData();

    formData.append("file", file);

    axios
      .post(`${config.defaults.api_url}/user/video`, formData, {
        // headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress(progressEvent) {
          setWorkoutVideoLoaded(
            (progressEvent.loaded / (1024 * 1024)).toFixed(2),
          );

          setWorkoutVideoProgress(true);
        },
      })
      .then((data) => {
        setWorkoutVideoProgress(false);
        toast.success("Workout video uploaded to storage successfully!");

        setWorkoutVideo(data?.data?.Key);
      })
      .catch(() => {
        alert("There is some error uploading file. please try again later");
        setWorkoutVideoProgress(false);
      });
  };

  const handleExplainedVideoFileChange = (file: any) => {
    setExplainedVideoName(file.name);

    setExplainedVideoType(file.type);

    const date = new Date();
    const seconds = date.getTime() / 1000;
    const file_name = `${seconds}-${uuidv4()}`;

    setExplainedVideo(file_name);

    setExplainedVideoSize((file.size / (1024 * 1024)).toFixed(2));

    const formData = new FormData();

    formData.append("file", file);

    axios
      .post(`${config.defaults.api_url}/user/video`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress(progressEvent) {
          setExplainedVideoLoaded(
            (progressEvent.loaded / (1024 * 1024)).toFixed(2),
          );

          setExplainedVideoProgress(true);
        },
      })
      .then((data) => {
        toast.success("Explained video uploaded to storage successfully!");
        setExplainedVideo(data?.data?.Key);
        setExplainedVideoProgress(false);
      })
      .catch(() => {
        alert("There is some error uploading file. please try again later");
        setExplainedVideoProgress(false);
      });
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setVideo({ ...video, [name]: value });
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const new_video = await createNewVideo({
      ...video,
      video_thumbnail: image,
      image_name: image_name,
      image_type: image_type,
      workout_video: workout_video,
      workout_video_name: workout_video_name,
      workout_video_type: workout_video_type,
      exercise_explained_video: explained_video,
      explained_video_name: explained_video_name,
      explained_video_type: explained_video_type,
    });

    if (new_video.data.error) {
      toast.error(new_video.data.message);
    } else {
      props.getVideos();
      setOpen(false);
      toast.success("New video created successfully!");
    }

    setIsSubmitting(false);
  };

  const trainerGender = useMemo(() => {
    if (props.commonData?.trainers) {
      const trainerId = parseInt(video.trainer);
      const currentTrainer = props.commonData.trainers.find(
        (e: any) => e.id === trainerId,
      );
      if (currentTrainer) {
        return currentTrainer.gender ? "Female" : "Male";
      }
    }
    return "Gender";
  }, [video, props.commonData]);

  const isSubmitDisabled = useMemo(() => {
    // Required Fields
    const fieldValidation = [
      "muscles",
      "equipment",
      "music_genre",
      "music_artist",
      "exercise",
      "video_duration",
      "rep_count",
      "set_count",
      "points",
      "title",
      "trainer",
    ];

    // True if there is an empty field
    const isEmptyField = fieldValidation.some((field: string) => {
      if (Object.hasOwn(video, field)) {
        const len = (video as any)[field]?.length;
        return len === 0;
      }
      return true;
    });

    if (workout_video_name === null) return true;
    if (image_name === null) return true;

    return (
      isEmptyField ||
      isSubmitting ||
      workout_video_progress ||
      explained_video_progress
    );
  }, [
    video,
    explained_video_progress,
    workout_video_progress,
    isSubmitting,
    workout_video_name,
    image_name,
  ]);

  return (
    <>
      <div ref={rootRef}>
        <Button color="primary" onClick={() => setOpen(true)}>
          <div className="flex items-center gap-x-3">
            <HiPlus className="text-xl" />
            Add Video
          </div>
        </Button>
        <Modal
          onClose={() => setOpen(false)}
          show={isOpen}
          size="3xl"
          position="top-center"
          root={rootRef.current ?? undefined}
        >
          <Modal.Header className="border-b border-gray-200 !p-6 dark:border-gray-700">
            <strong>Add New Video</strong>
          </Modal.Header>
          <Modal.Body>
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
              <div>
                <Label htmlFor="firstName">Title</Label>
                <div className="mt-1">
                  <TextInput
                    id="title"
                    name="title"
                    placeholder="Title"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div>
                <Label>{"Brief (Optional)"}</Label>
                <div className="mt-1">
                  <Textarea
                    id="brief"
                    name="brief"
                    placeholder="Brief"
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div>
                <Label>Workout Video</Label>
                <div className="mt-1">
                  <FileUploader
                    name="file"
                    handleChange={handleWorkoutVideoFileChange}
                    types={["mp4", "MP4"]}
                  >
                    <div className="mt-1">
                      <div className="flex w-full items-center justify-center">
                        <label
                          htmlFor="dropzone-file"
                          className="flex h-64 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 hover:bg-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:border-gray-500 dark:hover:bg-gray-800"
                        >
                          <div className="flex flex-col items-center justify-center pb-6 pt-5">
                            {workout_video ? (
                              <>
                                <p className="text-black">
                                  {" "}
                                  {workout_video_name}{" "}
                                </p>

                                <p className="mt-3 text-xs text-gray-500 dark:text-gray-400">
                                  Click to replace
                                </p>
                              </>
                            ) : (
                              <>
                                <svg
                                  className="mb-4 size-8 text-gray-500 dark:text-gray-400"
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 20 16"
                                >
                                  <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                                  />
                                </svg>
                                <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                  <span className="font-semibold">
                                    Click to upload
                                  </span>{" "}
                                  or drag and drop
                                </p>
                                <p className="text-xs text-gray-500 dark:text-gray-400">
                                  MP4 File Allowed Only
                                </p>
                              </>
                            )}
                          </div>
                        </label>
                      </div>
                    </div>
                  </FileUploader>
                </div>

                <div className="mt-3 text-[11px]">
                  <p>
                    Total Size: {workout_video_size} MB | Status:{" "}
                    {workout_video_progress
                      ? `In Progress ${workout_video_loaded} MB`
                      : "-"}
                  </p>
                </div>
              </div>

              <div>
                <Label>Video Thumbnail</Label>
                <FileUploader
                  name="file"
                  handleChange={handleFileChange}
                  types={fileTypes}
                >
                  <div className="mt-1">
                    <div className="flex w-full items-center justify-center">
                      <label
                        htmlFor="dropzone-file"
                        className="flex h-64 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 hover:bg-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:border-gray-500 dark:hover:bg-gray-800"
                      >
                        <div className="flex flex-col items-center justify-center pb-6 pt-5">
                          {previewUrl ? (
                            <>
                              <img
                                src={previewUrl}
                                alt=""
                                width={150}
                                height={150}
                              />

                              <p className="mt-3 text-xs text-gray-500 dark:text-gray-400">
                                Click to replace
                              </p>
                            </>
                          ) : (
                            <>
                              <svg
                                className="mb-4 size-8 text-gray-500 dark:text-gray-400"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 20 16"
                              >
                                <path
                                  stroke="currentColor"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                                />
                              </svg>
                              <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                <span className="font-semibold">
                                  Click to upload
                                </span>{" "}
                                or drag and drop
                              </p>
                              <p className="text-xs text-gray-500 dark:text-gray-400">
                                PNG, JPG (MAX. 800x400px)
                              </p>
                            </>
                          )}
                        </div>
                      </label>
                    </div>
                  </div>
                </FileUploader>
              </div>

              <div>
                <Label>Select Athlete</Label>
                <div className="mt-1">
                  <select
                    onChange={handleChange}
                    name="trainer"
                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                  >
                    <option selected disabled>
                      Choose
                    </option>
                    {props.trainers.map((x: any) => {
                      return (
                        <option key={x.id} value={x.id}>
                          {x.title}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>

              <div>
                <Label>Athlete Gender</Label>
                <div className="mt-1">
                  <TextInput
                    readOnly
                    disabled
                    id="trainer_gender"
                    name="trainer_gender"
                    placeholder="Type"
                    type="text"
                    value={trainerGender}
                  />
                </div>
              </div>

              <DropdownCheckbox
                muscles={allMuscles}
                value={selectedMuscles}
                onChange={handleChange}
                disabled={true}
              />

              <div>
                <Label>Video Duration</Label>
                <div className="mt-1">
                  <TextInput
                    id="video_duration"
                    name="video_duration"
                    placeholder="Video Duration"
                    onChange={handleChange}
                    value={video.video_duration}
                  />
                </div>
              </div>

              <div>
                <Label>Exercise Type</Label>
                <div className="mt-1">
                  <select
                    onChange={(e) => setExerciseType(e.target.value)}
                    name="exercise"
                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                  >
                    <option selected disabled>
                      Choose
                    </option>
                    {props.commonData?.exerciseTypes.map((x: any) => {
                      return (
                        <option key={x.id} value={x.id}>
                          {x.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>

              <ExerciseInput
                handleChange={handleChange}
                exerciseTypeId={exerciseTypeId}
                commonData={props?.commonData}
                selected={video.exercise}
              />

              <RepsInput
                exerciseTypeId={exerciseTypeId}
                commonData={props?.commonData}
                handleChange={handleChange}
                value={video.rep_count}
              />

              <div>
                <Label>Sets</Label>
                <div className="mt-1">
                  <TextInput
                    id="set_count"
                    name="set_count"
                    placeholder="Sets"
                    onChange={handleChange}
                    type="number"
                    value={video.set_count}
                  />
                </div>
              </div>

              <div>
                <Label>Points</Label>
                <div className="mt-1">
                  <TextInput
                    id="points"
                    name="points"
                    placeholder="Points"
                    onChange={handleChange}
                    type="number"
                  />
                </div>
              </div>

              <div>
                <Label>Equipment</Label>
                <div className="mt-1">
                  <select
                    onChange={handleChange}
                    name="equipment"
                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                  >
                    <option selected disabled>
                      Choose
                    </option>
                    {props.commonData?.equipments.map((x: any) => {
                      return (
                        <option key={x.id} value={x.id}>
                          {x.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>

              <div>
                <Label>Music Genre</Label>
                <div className="mt-1">
                  <select
                    onChange={handleChange}
                    name="music_genre"
                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                  >
                    <option selected disabled>
                      Choose
                    </option>
                    {props.commonData?.musicGenres.map((x: any) => {
                      return (
                        <option key={x.id} value={x.id}>
                          {x.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>

              <div>
                <Label>Music Artist</Label>
                <div className="mt-1">
                  <TextInput
                    id="music_artist"
                    name="music_artist"
                    placeholder="Type"
                    type="string"
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div>
                <Label>{"Workout Explaination Video (Optional)"}</Label>

                <div className="mt-1">
                  <FileUploader
                    name="file"
                    handleChange={handleExplainedVideoFileChange}
                    types={["mp4", "MP4"]}
                  >
                    <div className="mt-1">
                      <div className="flex w-full items-center justify-center">
                        <label
                          htmlFor="dropzone-file"
                          className="flex h-64 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 hover:bg-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                        >
                          <div className="flex flex-col items-center justify-center pb-6 pt-5">
                            {explained_video ? (
                              <>
                                <p className="text-black">
                                  {explained_video_name}
                                </p>

                                <p className="mt-3 text-xs text-gray-500 dark:text-gray-400">
                                  Click to replace
                                </p>
                              </>
                            ) : (
                              <>
                                <svg
                                  className="mb-4 size-8 text-gray-500 dark:text-gray-400"
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 20 16"
                                >
                                  <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                                  />
                                </svg>
                                <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                  <span className="font-semibold">
                                    Click to upload
                                  </span>{" "}
                                  or drag and drop
                                </p>
                                <p className="text-xs text-gray-500 dark:text-gray-400">
                                  MP4 File Allowed Only
                                </p>
                              </>
                            )}
                          </div>
                        </label>
                      </div>
                    </div>
                  </FileUploader>
                </div>

                <div className="mt-3 text-[11px]">
                  <p>
                    Total Size: {explained_video_size} MB | Status:{" "}
                    {explained_video_progress
                      ? `In Progress ${explained_video_loaded} MB`
                      : "-"}
                  </p>
                </div>
              </div>

              <div className="flex items-start"></div>
            </div>

            <div>
              <div className="mt-10">
                <label className="relative inline-flex cursor-pointer items-center">
                  <input
                    type="checkbox"
                    name="published"
                    className="peer sr-only"
                    onChange={handleChange}
                  />
                  <div className="peer h-6 w-11 rounded-full bg-gray-200 after:absolute after:left-[2px] after:top-[2px] after:size-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-blue-800"></div>
                  <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                    Published
                  </span>
                </label>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              color="primary"
              onClick={handleSubmit}
              disabled={isSubmitDisabled}
            >
              {isSubmitting ? "Creating..." : "Create"}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default AddVideoModal;
