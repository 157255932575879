/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/prop-types */

"use client";

import { Table, Pagination } from "flowbite-react";
import { HiInformationCircle, HiTrash } from "react-icons/hi";
import { useState } from "react";
import AsyncSelect from "react-select/async";
import { findVideos, getAllWorkouts } from "../../services/workouts";
import { toast } from "react-toastify";
import { getAllPrograms } from "../../services/programs";
import type { GetProgramsShortFormatResponse } from "../../types/apiResponses";

export enum FavoriteType {
  Workouts = "workout",
  Videos = "video",
  Programs = "program",
}

export enum CaseType {
  LowerCase = "lowercase",
  SentenceCase = "sentence",
}

export type FavoriteItem = {
  id: number;
  title: string;
};

type AsyncResultItem = {
  value: number;
  label: string;
};

type FavoriteTableProps = {
  items: FavoriteItem[];
  onClearAll: () => void;
  onRemove: (id: number) => void;
  type: FavoriteType;
  onSelect: (item: FavoriteItem) => void;
};

const FavoriteTable: React.FC<FavoriteTableProps> = ({
  items,
  onClearAll,
  onRemove,
  type,
  onSelect,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const totalPages = Math.ceil(items.length / itemsPerPage);

  const onPageChange = (page: number) => setCurrentPage(page);

  const paginatedItems = items.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage,
  );

  const loadWorkoutsData = async (query: string) => {
    const response = await getAllWorkouts({
      page: 1,
      take: 5,
      search: query,
      dropdown: true,
    });
    const data = response.data.results.workouts;
    console.log("loadWorkoutsData ", response.data.results.workouts);
    return data;
  };

  const loadVideosData = async (query: string) => {
    const response = await findVideos({
      page: 1,
      take: 5,
      search: query,
      dropdown: true,
    });
    console.log("loadVideosData ", response.data.results.videos);
    return response.data.results.videos;
  };

  const loadProgramsData = async (query: string) => {
    const response = await getAllPrograms({
      page: 1,
      take: 5,
      search: query,
      short_format: true,
    });
    return (response as GetProgramsShortFormatResponse).results.data;
  };

  function truncateString(str: string, maxLength: number): string {
    if (str.length <= maxLength) {
      return str;
    }
    return str.slice(0, maxLength) + "...";
  }

  const [selectedValue, setSelectedValue] = useState<AsyncResultItem | null>(
    null,
  );

  const getTitle = (favoriteType: FavoriteType, caseType: CaseType) => {
    let title = "";

    switch (favoriteType) {
      case FavoriteType.Workouts:
        title = "workout";
        break;
      case FavoriteType.Videos:
        title = "video";
        break;
      case FavoriteType.Programs:
        title = "program";
        break;
    }

    switch (caseType) {
      case CaseType.LowerCase:
        return title.toLowerCase();
      case CaseType.SentenceCase:
        return title.charAt(0).toUpperCase() + title.slice(1).toLowerCase();
      default:
        return title;
    }
  };

  const loadOptions = async (inputValue: string) => {
    switch (type) {
      case FavoriteType.Videos:
        return loadVideosData(inputValue);
      case FavoriteType.Workouts:
        return loadWorkoutsData(inputValue);
      case FavoriteType.Programs:
        return loadProgramsData(inputValue);
      default:
        return loadWorkoutsData(inputValue);
    }
  };

  return (
    <div className="mr-2 mt-2 overflow-visible">
      <div className="pb-4">
        <AsyncSelect
          isClearable={true}
          placeholder={`Add ${getTitle(type, CaseType.SentenceCase)}s`}
          noOptionsMessage={() => "No results"}
          loadOptions={loadOptions}
          value={selectedValue}
          onChange={(result: any) => {
            const selectedItem = result as AsyncResultItem;
            if (selectedItem?.value) {
              if (items.some((item) => item.id === selectedItem.value)) {
                toast.error(
                  `This ${getTitle(type, CaseType.LowerCase)} was already selected.`,
                );
              } else {
                onSelect({
                  id: selectedItem.value,
                  title: selectedItem.label,
                });
              }
              setSelectedValue(null);
            }
          }}
        />
      </div>

      {items.length === 0 ? (
        <div className="mr-2 mt-2 flex min-h-28 w-full items-start justify-center gap-1">
          {" "}
          <HiInformationCircle />
          {` There are no favorite ${getTitle(type, CaseType.LowerCase)}s yet.`}
        </div>
      ) : (
        <div>
          <div
            style={{ minHeight: "19rem" }}
            className="rounded-md border border-gray-300 p-1"
          >
            <Table hoverable>
              <Table.Head>
                <Table.HeadCell className="min-w-[13.6rem]">
                  Title
                </Table.HeadCell>
                <Table.HeadCell className="px-2">
                  <a
                    href="#"
                    onClick={onClearAll}
                    className="flex flex-row items-center gap-1 font-medium text-cyan-600 hover:underline dark:text-cyan-500"
                  >
                    <HiTrash /> <div>Clear all</div>
                  </a>
                </Table.HeadCell>
              </Table.Head>
              <Table.Body className="divide-y">
                {paginatedItems.map((item) => (
                  <Table.Row
                    key={item.id}
                    className="bg-white dark:border-gray-700 dark:bg-gray-800 "
                  >
                    <Table.Cell className="min-w-[13.6rem] whitespace-nowrap  text-gray-900 dark:text-white">
                      {truncateString(item.title, 22)}
                    </Table.Cell>
                    <Table.Cell className="px-2">
                      <a
                        href="#"
                        onClick={() => onRemove(item.id)}
                        className="text-cyan-600 hover:underline dark:text-cyan-500"
                      >
                        Remove
                      </a>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </div>

          {items.length > itemsPerPage && (
            <div className="mt-0 flex justify-between">
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={onPageChange}
                previousLabel="Previous"
                nextLabel="Next"
                showIcons={false}
                renderPaginationButton={() => null}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default FavoriteTable;
