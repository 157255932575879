import { Modal, ModalBody } from "flowbite-react";
import React from "react";

interface PreviewVideoModalProps {
  isOpen: boolean;
  toggle: () => void;
  videoUrl: string;
}

const PreviewVideoModal: React.FC<PreviewVideoModalProps> = ({
  isOpen,
  toggle,
  videoUrl,
}) => {
  return (
    <Modal show={isOpen} onClose={toggle} size="lg">
      <Modal.Header className="border-b border-gray-200 !p-6 dark:border-gray-700">
        <strong>Preview Video</strong>
      </Modal.Header>
      <ModalBody>
        {videoUrl ? (
          <video className="w-full" controls autoPlay>
            <track kind="captions" />
            <source src={videoUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        ) : (
          <p className="text-center">No video available</p>
        )}
      </ModalBody>
    </Modal>
  );
};

export default PreviewVideoModal;
