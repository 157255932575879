import axios from "../../util/axios";
import { ActionType } from "../../redux/actionTypes/index";
import type { Dispatch } from "redux";
import config from "../../config/config";

export function getTrainers(params: any) {
  return async function (dispatch: Dispatch) {
    try {
      await axios
        .get(`${config.defaults.api_url}/trainers`, { params })
        .then((res: any) => {
          dispatch({
            type: ActionType.GET_ALL_TRAINERS,
            payload: res,
          });
        });
    } catch (error) {
      console.log(error);

      dispatch({
        type: ActionType.GET_ALL_TRAINERS,
        payload: {
          message: "Unable to get trainers",
        },
      });
    }
  };
}
