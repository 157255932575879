/* eslint-disable no-unused-vars */
import config from "../config/config";
import axios from "../util/axios";

export async function createNewVideo(params: any) {
  return axios.post(`${config.defaults.api_url}/videos/create`, params);
}

export async function updateVideo(params: any, id: any) {
  return axios.post(`${config.defaults.api_url}/videos/update/${id}`, params);
}

export async function deleteVideo(id: any) {
  return axios.delete(`${config.defaults.api_url}/videos/delete/${id}`);
}

export async function getMuscleTargets() {
  return axios.get(`${config.defaults.api_url}/videos/muscles`);
}

export async function getCommonData() {
  return axios.get(`${config.defaults.api_url}/videos/common-data`);
}

export async function setFeaturedVideo(id: string, featured: string) {
  return axios.post(`${config.defaults.api_url}/videos/featured/${id}`, {
    featured,
  });
}

export async function getFeaturedVideos() {
  return axios.get(`${config.defaults.api_url}/videos/featured`);
}

export async function getAllInstructionalVideos() {
  return axios.get(
    `${config.defaults.api_url}/videos?page=1&take=10&format=short&exercise_type=Instructional`,
  );
}

export async function uploadVideo(
  fileData: any,
  callbackUploadProgress: (progress: number, filename: string) => void,
  filename: string,
) {
  const formData = new FormData();
  formData.append("file", fileData);
  return axios.post(`${config.defaults.api_url}/user/video`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
    onUploadProgress: (progressEvent) => {
      console.log(progressEvent, "progressEvent");
      const progress = Math.round(
        (progressEvent.loaded / (progressEvent as any)?.total) * 100,
      );
      callbackUploadProgress(progress, filename);
    },
  });
}

export async function getTotalVideos() {
  return axios.get(`${config.defaults.api_url}/videos/metrics`);
}
