/* eslint-disable tailwindcss/no-custom-classname */
import { useEffect, useRef, useState } from "react";
import { getWorkoutCategories, updateWorkout } from "../../services/workouts";
import { toast } from "react-toastify";
import { Button, Label, Modal, TextInput, Textarea } from "flowbite-react";
import { FileUploader } from "react-drag-drop-files";
import { difficulty, rounds } from "./data.json";
import { HiPlus } from "react-icons/hi";

export enum IntensityLevel {
  X1 = "X1",
  X2 = "X2",
  X3 = "X3",
  X4 = "X4",
  X5 = "X5",
  X6 = "X6",
  X7 = "X7",
}

const EditWorkoutModal = function (props: any) {
  const [isOpen, setOpen] = useState(false);
  const [update, setUpdate] = useState(false);

  const [updatedWorkout, setUpdatedWorkout]: any = useState({});

  const fileTypes = ["JPG", "PNG", "JPEG"];

  const rootRef = useRef<HTMLDivElement>(null);

  const [categories, setCategories] = useState([]);
  const [image, setImage]: any = useState(null);
  const [imageName, setImageName]: any = useState(null);
  const [imageType, setImageType]: any = useState(null);

  const [imageMobile, setImageMobile]: any = useState(null);
  const [imageNameMobile, setImageNameMobile]: any = useState(null);
  const [imageTypeMobile, setImageTypeMobile]: any = useState(null);

  const [previewUrlMobile, setPreviewUrlMobile]: any = useState(null);

  const [previewUrl, setPreviewUrl] = useState(null);

  const intensityArray = [
    { id: "Auto Assign", title: "Auto Assign" },
    ...Object.entries(IntensityLevel).map(([key, value]) => ({
      id: value,
      title: key,
    })),
  ];

  const handleFileChange = (file: any) => {
    const img = new Image();
    img.src = window.URL.createObjectURL(file);
    img.onload = () => {
      // @ts-expect-error:next-line

      setPreviewUrl(URL.createObjectURL(file));

      setImageName(file.name);

      setImageType(file.type);

      const reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => {
        setImage(reader.result);
      };
    };
  };

  const handleFileChangeMobile = (file: any) => {
    const img = new Image();
    img.src = window.URL.createObjectURL(file);
    img.onload = () => {
      setPreviewUrlMobile(URL.createObjectURL(file));

      setImageNameMobile(file.name);

      setImageTypeMobile(file.type);

      const reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => {
        setImageMobile(reader.result);
      };
    };
  };

  const handleChange = (e: any) => {
    const data: any = {
      ...setUpdatedWorkout,
    };

    data[e.target.name] =
      e.target.name === "published" || e.target.name === "featured"
        ? e.target.checked
        : e.target.value;

    setUpdatedWorkout(data);
  };

  const handleSubmit = async () => {
    setUpdate(true);

    const workoutData = { ...updatedWorkout };

    if (workoutData.intensity === "Auto Assign") {
      delete workoutData.intensity;
    }

    const update_workout = await updateWorkout(
      {
        ...workoutData,
        ...(image ? { cover_image: image } : {}),
        ...(imageMobile ? { cover_image_mobile: imageMobile } : {}),
        ...(imageName ? { image_name: imageName } : {}),
        ...(imageType ? { image_type: imageType } : {}),
        ...(imageNameMobile ? { image_name_mobile: imageNameMobile } : {}),
        ...(imageTypeMobile ? { image_type_mobile: imageTypeMobile } : {}),
      },
      props.workout.id,
    );

    if (update_workout.data.error) {
      toast.error(update_workout.data.message);
    } else {
      props.getWorkouts();
      setOpen(false);
      toast.success("Workout updated successfully!");
    }

    setUpdate(false);
  };

  const fetchCategories = async () => {
    try {
      const response = await getWorkoutCategories();
      setCategories(response.data);
    } catch {
      toast.error("Failed to load workout categories");
    }
  };

  useEffect(() => {
    if (isOpen) {
      fetchCategories();
    }
  }, [isOpen]);

  return (
    <>
      <div ref={rootRef}>
        <Button color="primary" onClick={() => setOpen(true)}>
          <div className="flex items-center gap-x-3">
            <HiPlus className="text-xl" />
            Edit Workout
          </div>
        </Button>
        <Modal
          onClose={() => setOpen(false)}
          show={isOpen}
          size="3xl"
          position="top-center"
          root={rootRef.current ?? undefined}
        >
          <Modal.Header className="border-b border-gray-200 !p-6 dark:border-gray-700">
            <strong>Edit Workout - {props.workout.title}</strong>
          </Modal.Header>
          <Modal.Body>
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
              <div>
                <Label htmlFor="firstName">Title</Label>
                <div className="mt-1">
                  <TextInput
                    id="title"
                    name="title"
                    defaultValue={props.workout.title}
                    placeholder="Title"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div>
                <Label htmlFor="lastName">Description</Label>
                <div className="mt-1">
                  <Textarea
                    id="description"
                    name="description"
                    defaultValue={props.workout.description}
                    placeholder="description"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div>
                <Label>Category</Label>
                <div className="mt-1">
                  <select
                    onChange={handleChange}
                    name="category"
                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                  >
                    <option selected disabled>
                      Choose
                    </option>
                    {categories.map((category: any) => (
                      <option
                        key={category.id}
                        value={category.id}
                        selected={props.workout.category_id === category.id}
                      >
                        {category.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div>
                <Label>Difficulty</Label>
                <div className="mt-1">
                  <select
                    onChange={handleChange}
                    name="difficulty"
                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                  >
                    <option selected disabled>
                      Choose
                    </option>
                    {difficulty.map((x: any, i: number) => {
                      if (props.workout.difficulty == i) {
                        return (
                          <option key={i} value={i} selected>
                            {x}
                          </option>
                        );
                      } else {
                        return (
                          <option key={i} value={i}>
                            {x}
                          </option>
                        );
                      }
                    })}
                  </select>
                </div>
              </div>

              <div>
                <Label>Select Trainer</Label>
                <div className="mt-1">
                  <select
                    onChange={handleChange}
                    name="trainer"
                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                  >
                    <option selected disabled>
                      Choose
                    </option>
                    {props.trainers.map((trainer: any, i: any) => {
                      if (props.workout.trainer_id === trainer.id) {
                        return (
                          <option key={i} value={trainer.id} selected>
                            {trainer.title}
                          </option>
                        );
                      } else {
                        return (
                          <option key={i} value={trainer.id}>
                            {trainer.title}
                          </option>
                        );
                      }
                    })}
                  </select>
                </div>
              </div>

              <div>
                <Label>Select Intensity</Label>
                <div className="mt-1">
                  <select
                    onChange={handleChange}
                    name="intensity"
                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                  >
                    <option selected disabled>
                      Choose
                    </option>
                    {intensityArray.map((item, i) => {
                      return (
                        <option
                          key={i}
                          value={item.id}
                          selected={props.workout.intensity === item.id}
                        >
                          {item.title}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>

              <div>
                <Label>Rounds</Label>
                <div className="mt-1">
                  <select
                    name="rounds"
                    onChange={handleChange}
                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                  >
                    {rounds.map((x: any, i: number) => {
                      if (props.workout.rounds - 1 === i) {
                        return (
                          <option key={i} value={i + 1} selected>
                            {x}
                          </option>
                        );
                      } else {
                        return (
                          <option key={i} value={i + 1}>
                            {x}
                          </option>
                        );
                      }
                    })}
                  </select>
                </div>
              </div>

              {/* Empty space */}
              <div></div>

              <div>
                <Label>Cover Image</Label>
                <FileUploader
                  name="file"
                  handleChange={handleFileChange}
                  types={fileTypes}
                >
                  <div className="mt-1">
                    <div className="flex w-full items-center justify-center">
                      <label
                        htmlFor="dropzone-file"
                        className="dark:hover:bg-bray-800 flex h-64 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 hover:bg-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                      >
                        <div className=" flex flex-col items-center justify-center rounded pb-6 pt-5">
                          {props.workout.cover_image ? (
                            <>
                              {previewUrl ? (
                                <>
                                  <img
                                    className="cover-image"
                                    src={previewUrl}
                                    alt=""
                                    width={150}
                                    height={150}
                                  />

                                  <p className="mt-3 text-xs text-gray-500 dark:text-gray-400">
                                    Click to replace{" "}
                                  </p>
                                </>
                              ) : (
                                <>
                                  <img
                                    className="cover-image"
                                    src={props.workout.cover_image}
                                    alt=""
                                    width={150}
                                    height={150}
                                  />

                                  <p className="mt-3 text-xs text-gray-500 dark:text-gray-400">
                                    Click to replace
                                  </p>
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              {previewUrlMobile ? (
                                <>
                                  <img
                                    className="cover-image"
                                    src={previewUrlMobile}
                                    alt=""
                                    width={150}
                                    height={150}
                                  />

                                  <p className="mt-3 text-xs text-gray-500 dark:text-gray-400">
                                    Click to replace
                                  </p>
                                </>
                              ) : (
                                <>
                                  <svg
                                    className="mb-4 size-8 text-gray-500 dark:text-gray-400"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 20 16"
                                  >
                                    <path
                                      stroke="currentColor"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                      d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                                    />
                                  </svg>
                                  <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                    <span className="font-semibold">
                                      Click to upload
                                    </span>{" "}
                                    or drag and drop
                                  </p>
                                  <p className="text-xs text-gray-500 dark:text-gray-400">
                                    PNG, JPG (MAX. 800x400px)
                                  </p>
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </label>
                    </div>
                  </div>
                </FileUploader>
              </div>

              <div>
                <Label>Cover Image Mobile</Label>
                <FileUploader
                  name="file"
                  handleChange={handleFileChangeMobile}
                  types={fileTypes}
                >
                  <div className="mt-1">
                    <div className="flex w-full items-center justify-center">
                      <label
                        htmlFor="dropzone-file"
                        className="dark:hover:bg-bray-800 flex h-64 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 hover:bg-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                      >
                        <div className="flex flex-col items-center justify-center rounded pb-6 pt-5">
                          {props.workout.cover_image_mobile ? (
                            <>
                              {imageMobile ? (
                                <>
                                  <img
                                    className="cover-image"
                                    src={imageMobile}
                                    alt=""
                                    width={150}
                                    height={150}
                                  />

                                  <p className="mt-3 text-xs text-gray-500 dark:text-gray-400">
                                    Click to replace{" "}
                                  </p>
                                </>
                              ) : (
                                <>
                                  <img
                                    className="cover-image"
                                    src={props.workout.cover_image_mobile}
                                    alt=""
                                    width={300}
                                    height={300}
                                  />

                                  <p className="mt-3 text-xs text-gray-500 dark:text-gray-400">
                                    Click to replace{" "}
                                  </p>
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              {imageMobile ? (
                                <>
                                  <img
                                    className="cover-image"
                                    src={imageMobile}
                                    alt=""
                                    width={300}
                                    height={300}
                                  />

                                  <p className="mt-3 text-xs text-gray-500 dark:text-gray-400">
                                    Click to replace
                                  </p>
                                </>
                              ) : (
                                <>
                                  <svg
                                    className="mb-4 size-8 text-gray-500 dark:text-gray-400"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 20 16"
                                  >
                                    <path
                                      stroke="currentColor"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                      d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                                    />
                                  </svg>
                                  <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                    <span className="font-semibold">
                                      Click to upload
                                    </span>{" "}
                                    or drag and drop
                                  </p>
                                  <p className="text-xs text-gray-500 dark:text-gray-400">
                                    PNG, JPG (MAX. 800x400px)
                                  </p>
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </label>
                    </div>
                  </div>
                </FileUploader>
              </div>

              <div className="flex items-start"></div>
            </div>

            <div>
              <div className="mt-10">
                <label className="relative inline-flex cursor-pointer items-center">
                  <input
                    type="checkbox"
                    name="published"
                    className="peer sr-only"
                    onChange={handleChange}
                    defaultChecked={props.workout.published}
                  />
                  <div className="peer h-6 w-11 rounded-full bg-gray-200 after:absolute after:left-[2px] after:top-[2px] after:size-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-blue-800"></div>
                  <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                    Published
                  </span>
                </label>
              </div>
            </div>

            <div>
              <div className="mt-10">
                <label className="relative inline-flex cursor-pointer items-center">
                  <input
                    type="checkbox"
                    name="featured"
                    className="peer sr-only"
                    onChange={handleChange}
                    defaultChecked={props.workout.featured}
                  />
                  <div className="peer h-6 w-11 rounded-full bg-gray-200 after:absolute after:left-[2px] after:top-[2px] after:size-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-blue-800"></div>
                  <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                    Featured
                  </span>
                </label>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button color="primary" onClick={handleSubmit} disabled={update}>
              {update ? "Updating..." : "Update"}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default EditWorkoutModal;
