/* eslint-disable tailwindcss/no-custom-classname */
import { useLayoutEffect, useMemo, useRef, useState } from "react";
import { getAllWorkouts } from "../../services/workouts";
import { createNewProgram } from "../../services/programs";
import { toast } from "react-toastify";
import { Button, Label, Modal, TextInput, Textarea } from "flowbite-react";
import { HiPlus } from "react-icons/hi";
import { FileUploader } from "react-drag-drop-files";
import AsyncSelect from "react-select/async";
import { ProgramDifficultyOptions } from "./list";

const AddProgramModal = function (props: any) {
  const [isOpen, setOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [previewUrl, setPreviewUrl] = useState(null);

  const [days, setDays]: any = useState([]);
  const [day, setDay] = useState(0);

  const loadData = async (query: string) => {
    const response = await getAllWorkouts({
      page: 1,
      take: 20,
      search: query,
      dropdown: true,
    });
    const data = response.data.results.workouts;
    return data;
  };

  const [program, setProgram] = useState({
    title: "",
    description: "",
    trainer_id: 0,
    program_duration: 0,
    program_difficulty: "Easy",
    published: false,
    status: 0,
  });

  const fileTypes = ["JPG", "PNG", "JPEG"];

  const rootRef = useRef<HTMLDivElement>(null);

  const [image, setImage] = useState(null);
  const [imageName, setImageName] = useState(null);
  const [imageType, setImageType] = useState(null);
  const [name, setName] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);

  const formValid = useMemo(() => {
    return (
      program.title.trim() !== "" &&
      program.description.trim() !== "" &&
      program.program_duration != 0 &&
      image != null
    );
  }, [program, image]);

  const handleFileInput = (e: any) => {
    const file = e.target.files[0];

    const reader = new FileReader();

    setName(file.name);

    reader.readAsDataURL(file);

    reader.onload = () => {
      // @ts-expect-error:next-line
      setSelectedFile(reader.result);
    };
  };

  const handleFileChange = (file: any) => {
    const img = new Image();
    img.src = window.URL.createObjectURL(file);
    img.onload = () => {
      // @ts-expect-error:next-line

      setPreviewUrl(URL.createObjectURL(file));

      setImageName(file.name);

      setImageType(file.type);

      const reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => {
        // @ts-expect-error:next-line
        setImage(reader.result);
      };
    };
  };

  const handleChange = (e: any) => {
    const data: any = {
      ...program,
    };

    if (e.target.name === "program_duration") {
      setDay(parseInt(e.target.value));
    }

    data[e.target.name] =
      e.target.name === "published" ? e.target.checked : e.target.value;

    setProgram(data);
  };

  const scrollX = window.scrollX;
  const scrollY = window.scrollY;

  useLayoutEffect(() => {
    window.scrollTo(scrollX, scrollY);
  });

  const handleSubmit = async () => {
    setIsSubmitting(true);

    const new_program = await createNewProgram({
      ...program,
      cover_image: image,
      image_name: imageName,
      image_type: imageType,
      days,
      name,
      file: selectedFile,
    });

    if (new_program.data.error) {
      toast.error(new_program.data.message);
    } else {
      props.getPrograms();
      setOpen(false);
      toast.success("New program created successfully!");
    }

    setIsSubmitting(false);
  };

  return (
    <>
      <div ref={rootRef}>
        <Button color="primary" onClick={() => setOpen(true)}>
          <div className="flex items-center gap-x-3">
            <HiPlus className="text-xl" />
            Add Program
          </div>
        </Button>
        <Modal
          onClose={() => setOpen(false)}
          show={isOpen}
          size="7xl"
          position="top-center"
          root={rootRef.current ?? undefined}
        >
          <Modal.Header className="border-b border-gray-200 !p-6 dark:border-gray-700">
            <strong>Add New Program</strong>
          </Modal.Header>
          <Modal.Body>
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
              <div>
                <Label htmlFor="firstName">Title</Label>
                <div className="mt-1">
                  <TextInput
                    id="title"
                    name="title"
                    placeholder="Title"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div>
                <Label htmlFor="lastName">Description</Label>
                <div className="mt-1">
                  <Textarea
                    id="description"
                    name="description"
                    placeholder="description"
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div>
                <Label htmlFor="lastName">Program Duration</Label>
                <div className="mt-1">
                  <TextInput
                    id="program_duration"
                    name="program_duration"
                    placeholder="Program Duration"
                    type="number"
                    defaultValue={0}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div>
                <Label htmlFor="program_difficulty">Difficulty</Label>
                <div className="mt-1">
                  <select
                    id="program_difficulty"
                    name="program_difficulty"
                    value={program.program_difficulty}
                    onChange={handleChange}
                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                  >
                    <option value="" disabled>
                      Choose Difficulty
                    </option>
                    {ProgramDifficultyOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div>
                <Label>Select Athlete</Label>
                <div className="mt-1">
                  <select
                    onChange={handleChange}
                    name="trainer"
                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                  >
                    <option selected disabled>
                      Choose
                    </option>
                    {props.trainers.map((x: any) => {
                      return (
                        <>
                          <option value={x.id}>{x.title}</option>
                        </>
                      );
                    })}
                  </select>
                </div>
              </div>

              <div></div>

              <div>
                <Label>Cover Image</Label>
                <FileUploader
                  name="file"
                  handleChange={handleFileChange}
                  types={fileTypes}
                >
                  <div className="mt-1">
                    <div className="flex w-full items-center justify-center">
                      <label
                        htmlFor="dropzone-file"
                        className="dark:hover:bg-bray-800 flex h-64 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 hover:bg-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                      >
                        <div className="flex flex-col items-center justify-center pb-6 pt-5">
                          {previewUrl ? (
                            <>
                              <img
                                src={previewUrl}
                                alt=""
                                width={150}
                                height={150}
                              />

                              <p className="mt-3 text-xs text-gray-500 dark:text-gray-400">
                                Click to replace
                              </p>
                            </>
                          ) : (
                            <>
                              <svg
                                className="mb-4 size-8 text-gray-500 dark:text-gray-400"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 20 16"
                              >
                                <path
                                  stroke="currentColor"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                                />
                              </svg>
                              <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                <span className="font-semibold">
                                  Click to upload
                                </span>{" "}
                                or drag and drop
                              </p>
                              <p className="text-xs text-gray-500 dark:text-gray-400">
                                PNG, JPG (MAX. 800x400px)
                              </p>
                            </>
                          )}
                        </div>
                      </label>
                    </div>
                  </div>
                </FileUploader>
              </div>

              <div></div>

              <div className="mt-10">
                <label className="relative inline-flex cursor-pointer items-center">
                  <input
                    type="checkbox"
                    name="featured"
                    className="peer sr-only"
                    onChange={handleChange}
                  />
                  <div className="peer h-6 w-11 rounded-full bg-gray-200 after:absolute after:left-[2px] after:top-[2px] after:size-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-blue-800"></div>
                  <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                    Featured
                  </span>
                </label>
              </div>

              <div>
                <Label>Nutrition Plan</Label>
                <div className="mt-1">
                  <input type="file" onChange={handleFileInput} />
                </div>
              </div>
              {day > 0 &&
                Array(day)
                  .fill({})
                  .map((d: any, i: any) => {
                    return (
                      <>
                        <div className="mt-2 max-w-md">
                          <Label>Workout Day</Label>

                          <TextInput
                            id={d}
                            name="day"
                            disabled={true}
                            defaultValue={i + 1}
                          />
                        </div>

                        <div className="max-w-md">
                          <Label> Choose Workout </Label>
                          <AsyncSelect
                            isClearable={true}
                            className="mt-2"
                            defaultValue={[]}
                            loadOptions={loadData}
                            onInputChange={loadData}
                            noOptionsMessage={() => "No results"}
                            onChange={(v: any) => {
                              const _day = i + 1;

                              const node = {
                                day: _day,
                                workout: v.value,
                              };

                              const foundNode = days.filter(
                                (x: any) => x.day === _day,
                              );

                              if (foundNode.length > 0) {
                                const updatedNodes = days.map((x: any) => {
                                  if (x.day === _day) {
                                    x.workout = v.value;
                                  }

                                  return x;
                                });

                                setDays(updatedNodes);
                              } else {
                                setDays([...days, node]);
                              }
                            }}
                          />
                        </div>
                      </>
                    );
                  })}

              <div className="flex items-start"></div>
            </div>

            <div>
              <div className="mt-10">
                <label className="relative inline-flex cursor-pointer items-center">
                  <input
                    type="checkbox"
                    name="published"
                    className="peer sr-only"
                    onChange={handleChange}
                  />
                  <div className="peer h-6 w-11 rounded-full bg-gray-200 after:absolute after:left-[2px] after:top-[2px] after:size-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-blue-800"></div>
                  <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                    Published
                  </span>
                </label>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              color="primary"
              onClick={handleSubmit}
              disabled={isSubmitting || !formValid}
            >
              {isSubmitting ? "Creating..." : "Create"}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default AddProgramModal;
