/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Label, Modal, Spinner, TextInput } from "flowbite-react";
import type { FormikHelpers } from "formik";
import { Field, Form, Formik } from "formik";
import { useCallback, useEffect, useMemo, useState } from "react";
import { HiInformationCircle, HiKey } from "react-icons/hi";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { getUserDetails, updateUser } from "../../services/user";
import type { UserAttribute, UserResponse } from "../../types/apiResponses";
import { blueThemeSpinner } from "./EditUserModal";

interface ChangeUserPasswordProps {
  user: {
    id: string;
    identity_provider: string;
  };
  getUsers: () => void;
}

interface FormValues {
  password: string;
  confirmPassword: string;
}

const ChangeUserPassword: React.FC<ChangeUserPasswordProps> = ({
  user,
  getUsers,
}) => {
  const [isOpen, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [userDetails, setUserDetails] = useState<UserResponse | null>(null);

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, "Password must be at least 8 characters long")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password")], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const fetchUserDetails = useCallback(async () => {
    setLoading(true);
    try {
      const userData = await getUserDetails(user.id);
      setUserDetails(userData);
    } catch (error) {
      toast.error("Failed to fetch user details");
    } finally {
      setLoading(false);
    }
  }, [user.id]);

  useEffect(() => {
    if (isOpen) {
      fetchUserDetails();
    }
  }, [isOpen, fetchUserDetails]);

  const passwordCanBeUpdated: boolean = useMemo(() => {
    if (!userDetails) return false;

    console.log(userDetails);
    const identityProvider = userDetails?.userCognito?.UserAttributes?.find(
      (attr: UserAttribute) => attr.Name === "custom:identity_provider",
    )?.Value;

    if (identityProvider === undefined) {
      return true;
    } else if (identityProvider === "email") {
      return true;
    } else {
      return false;
    }
  }, [userDetails]);

  const handleSubmit = async (
    values: FormValues,
    { setSubmitting, setFieldError }: FormikHelpers<FormValues>,
  ) => {
    try {
      await updateUser({ new_password: values.password }, user.id);
      toast.success(`User password changed`);
      setOpen(false);
      getUsers();
    } catch (error) {
      toast.error("Failed to change password");
      setFieldError("password", "Failed to change password");
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <Button color="primary" onClick={() => setOpen(true)}>
        <div className="flex items-center gap-x-2">
          <HiKey className="text-lg" />
          Change password
        </div>
      </Button>
      <Modal
        onClose={() => {
          setLoading(true);
          setOpen(false);
        }}
        show={isOpen}
        size="lg"
      >
        <Modal.Header className="border-b border-gray-200 !p-3 dark:border-gray-700">
          <strong>Change user password</strong>
        </Modal.Header>
        <Modal.Body className="p-6">
          {loading ? (
            <div className="flex min-h-32 items-center justify-center ">
              <Spinner theme={blueThemeSpinner} color="info" size="xl" />
            </div>
          ) : (
            <div>
              {passwordCanBeUpdated ? (
                <Formik
                  initialValues={{ password: "", confirmPassword: "" }}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({ isSubmitting, errors, touched }) => (
                    <Form className="flex max-w-md flex-col gap-4 pb-2">
                      <div>
                        <div className="mb-2 block">
                          <Label
                            htmlFor={`new-password-${user.id}`}
                            color={
                              errors.password && touched.password
                                ? "failure"
                                : "default"
                            }
                            value="New password (8 characters, 1 special)"
                          />
                        </div>
                        <Field name="password">
                          {({ field }: { field: any }) => (
                            <TextInput
                              {...field}
                              id={`new-password-${user.id}`}
                              type="password"
                              placeholder="••••••••"
                              autoComplete="new-password"
                              color={
                                errors.password && touched.password
                                  ? "failure"
                                  : undefined
                              }
                              helperText={
                                errors.password && touched.password ? (
                                  <>
                                    <span className="font-medium">Oops!</span>{" "}
                                    {errors.password}
                                  </>
                                ) : null
                              }
                            />
                          )}
                        </Field>
                      </div>
                      <div>
                        <div className="mb-2 block">
                          <Label
                            htmlFor={`confirm-new-password-${user.id}`}
                            color={
                              errors.confirmPassword && touched.confirmPassword
                                ? "failure"
                                : "default"
                            }
                            value="Confirm new password"
                          />
                        </div>
                        <Field name="confirmPassword">
                          {({ field }: { field: any }) => (
                            <TextInput
                              {...field}
                              id={`confirm-new-password-${user.id}`}
                              type="password"
                              placeholder="••••••••"
                              autoComplete="new-password"
                              onFocus={(event) => {
                                event.target.setAttribute(
                                  "autocomplete",
                                  "off",
                                );
                                console.log(event.target.autocomplete);
                              }}
                              color={
                                errors.confirmPassword &&
                                touched.confirmPassword
                                  ? "failure"
                                  : undefined
                              }
                              helperText={
                                errors.confirmPassword &&
                                touched.confirmPassword ? (
                                  <>
                                    <span className="font-medium">Oops!</span>{" "}
                                    {errors.confirmPassword}
                                  </>
                                ) : null
                              }
                            />
                          )}
                        </Field>
                      </div>
                      <div className="flex items-center gap-x-3">
                        <Button
                          color="primary"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? "Loading..." : "Change password"}
                        </Button>
                        <Button color="gray" onClick={() => setOpen(false)}>
                          Cancel
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              ) : (
                <div className="flex min-h-32 flex-col items-center justify-center gap-2 pb-4 text-gray-500">
                  <HiInformationCircle size={32} />
                  <Label className="text-center text-base text-gray-500">
                    Passwords for users with third-party OAuth (Gmail/Apple)
                    cannot be changed.
                  </Label>
                </div>
              )}
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ChangeUserPassword;
