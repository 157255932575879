import { ActionType } from "../actionTypes/index";

const initialState = {
  videos: [],
  event: null,
};

const videosReducer = (state = initialState, action: any): any => {
  console.log("=== Reducer Videos ===");
  console.log(action);
  switch (action.type) {
    case ActionType.GET_ALL_VIDEOS:
      return {
        ...state,
        videos: action.payload.data.results,
        event: action.type,
      };
    case ActionType.GET_ALL_VIDEOS_FAILED:
      return {
        ...state,
        event: action.type,
      };
    default:
      return state;
  }
};

export default videosReducer;
