import axios from "../util/axios";
import config from "../config/config";

export async function getDailyWorkouts() {
  const result = await axios.get(`${config.defaults.api_url}/workouts/daily`);
  return result.data.results;
}

export async function getAllWorkouts(params: any) {
  return axios.get(`${config.defaults.api_url}/workouts`, { params });
}

export async function createNewWorkout(params: any) {
  return axios.post(`${config.defaults.api_url}/workouts/create`, params);
}

export async function updateWorkout(params: any, id: any) {
  return axios.post(`${config.defaults.api_url}/workouts/update/${id}`, params);
}

export async function deleteWorkout(id: any) {
  return axios.delete(`${config.defaults.api_url}/workouts/delete/${id}`);
}

export async function findVideos(params: any) {
  return axios.get(`${config.defaults.api_url}/videos`, { params });
}

export async function getWorkoutPlaylist(id: any) {
  return axios.get(`${config.defaults.api_url}/workouts/${id}/playlist`);
}

export async function getFeaturedWorkouts() {
  return axios.get(`${config.defaults.api_url}/workouts/featured`);
}

export async function savePlaylist(params: any, id: any) {
  return axios.post(
    `${config.defaults.api_url}/workouts/save/playlist/${id}`,
    params,
  );
}

export async function removePlaylistVideo(workout_id: any, video_id: any) {
  return axios.post(
    `${config.defaults.api_url}/workouts/delete/playlist/video/${workout_id}/${video_id}`,
  );
}

export async function featuredWorkout(params: any, id: any) {
  return axios.post(
    `${config.defaults.api_url}/workouts/featured/${id}`,
    params,
  );
}

export async function getTotalWorkouts() {
  return axios.get(`${config.defaults.api_url}/workouts/metrics`);
}

export async function getWorkoutCategories() {
  return axios.get(`${config.defaults.api_url}/workout-categories`);
}

export async function changeDailyWorkout(workoutId: number) {
  return axios.post(`${config.defaults.api_url}/workouts/daily`, { workoutId });
}
