import type { CustomFlowbiteTheme } from "flowbite-react";
import { Button, Label, Modal, Spinner, TextInput } from "flowbite-react";
import { useCallback, useEffect, useState } from "react";
import {
  getAllExerciseTypes,
  getAllMuscles,
  getExerciseDetails,
} from "../../services/exercises";
import type {
  ExerciseForm,
  ExerciseType,
  Muscle,
} from "../../types/apiResponses";

export const blueThemeSpinner: CustomFlowbiteTheme["spinner"] = {
  color: {
    info: "fill-blue-700",
  },
};

export const ThemeTooltip: CustomFlowbiteTheme["tooltip"] = {
  target: "w-full",
};

export interface ExerciseModalProps {
  id?: string;
  updating: boolean;
  exercise: ExerciseForm;
  isOpen: boolean;
  setExercise: (exercise: ExerciseForm) => void;
  handleSubmit: () => void;
  handleClose: () => void;
}

const ExerciseModal = function (props: ExerciseModalProps) {
  const {
    id,
    isOpen,
    updating,
    exercise,
    setExercise,
    handleSubmit,
    handleClose,
  } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [types, setTypes] = useState<ExerciseType[]>([]);
  const [allMuscles, setAllMuscles] = useState<Muscle[]>([]);

  const fetchExerciseDetails = useCallback(
    async (id?: string) => {
      if (id) {
        const exerciseData = await getExerciseDetails(id);
        setExercise({
          id: exerciseData.results.id,
          name: exerciseData.results.name,
          exerciseType: exerciseData.results.exerciseType.id,
          muscles: exerciseData.results.muscles.map((x: any) => x.id),
        });
      } else {
        setExercise({
          id: "",
          name: "",
          exerciseType: "",
          muscles: [],
        });
      }
    },
    [setExercise],
  );

  const fetchFormData = useCallback(async () => {
    const musclesData = await getAllMuscles();
    setAllMuscles(musclesData.results);
    const typesData = await getAllExerciseTypes();
    setTypes(typesData.results);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    fetchFormData();
    fetchExerciseDetails(id);
    setIsLoading(false);
  }, [fetchExerciseDetails, fetchFormData, id]);

  const handleInput = (e: any) => {
    setExercise({ ...exercise, [e.target.name]: e.target.value });
  };

  const handleSelectMuscle = (e: any) => {
    if (exercise.muscles.includes(e.target.value)) {
      setExercise({
        ...exercise,
        muscles: exercise.muscles.filter((x) => x !== e.target.value),
      });
    } else {
      setExercise({
        ...exercise,
        muscles: [...exercise.muscles, e.target.value],
      });
    }
  };
  const handleSelectType = (e: any) => {
    setExercise({
      ...exercise,
      exerciseType: e.target.value,
    });
  };

  return (
    <Modal size="3xl" position="top-center" show={isOpen} onClose={handleClose}>
      <Modal.Header className="border-b border-gray-200 !p-6 dark:border-gray-700">
        <strong>{id ? "Edit" : "Add"} Exercise</strong>
      </Modal.Header>
      <Modal.Body>
        {isLoading ? (
          <div className="flex min-h-52 items-center justify-center ">
            <Spinner theme={blueThemeSpinner} color="info" size="xl" />
          </div>
        ) : (
          <div>
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
              <div>
                <Label htmlFor="name" className="font-semibold">
                  Name
                </Label>
                <div className="mt-1">
                  <TextInput
                    id={`name-${props.exercise.id}`}
                    name="name"
                    value={exercise?.name}
                    placeholder="Name"
                    onChange={handleInput}
                  />
                </div>
              </div>
              <div>
                <Label className="font-semibold">Exercise Type</Label>
                <div className="mt-1">
                  <select
                    onChange={handleSelectType}
                    name="exercise"
                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                  >
                    <option selected disabled>
                      Choose
                    </option>
                    {types.map((x: any) => {
                      return (
                        <option
                          key={x.id}
                          value={x.id}
                          selected={x.id === exercise.exerciseType}
                        >
                          {x.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
            <div className="py-4">
              <Label className="font-semibold">Muscle Target</Label>
              {allMuscles && allMuscles.length > 0 ? (
                <div className="mt-4 grid grid-cols-4 gap-6">
                  {allMuscles.map((muscle: Muscle, index: number) => (
                    <div key={index} className="flex items-center gap-2">
                      <Button
                        onClick={() =>
                          handleSelectMuscle({ target: { value: muscle.id } })
                        }
                        className={`w-full ${exercise?.muscles?.includes(muscle.id) ? "bg-primary-700" : "bg-primary-300"}`}
                      >
                        {muscle.name}
                      </Button>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="flex min-h-52 w-full items-center justify-center ">
                  <Spinner theme={blueThemeSpinner} color="info" size="xl" />
                </div>
              )}
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        {id ? (
          <Button color="primary" disabled={updating} onClick={handleSubmit}>
            {updating ? "Updating..." : "Update"}
          </Button>
        ) : (
          <Button color="primary" disabled={updating} onClick={handleSubmit}>
            {updating ? "Creating..." : "Create"}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default ExerciseModal;
