import { Button, Modal } from "flowbite-react";
import { deleteTrainer } from "../../services/trainers";
import { HiOutlineExclamationCircle, HiTrash } from "react-icons/hi";
import { useState } from "react";
import { toast } from "react-toastify";

const DeleteTrainerModal = function (props: any) {
  const [isOpen, setOpen] = useState(false);
  const [deleting, setDelete] = useState(false);

  return (
    <>
      <Button color="failure" onClick={() => setOpen(true)}>
        <div className="flex items-center gap-x-2">
          <HiTrash className="text-lg" />
          Delete Athlete
        </div>
      </Button>
      <Modal onClose={() => setOpen(false)} show={isOpen} size="md">
        <Modal.Header className="px-6 pb-0 pt-6">
          <span className="sr-only">Delete trainer</span>
        </Modal.Header>
        <Modal.Body className="px-6 pb-6 pt-0">
          <div className="flex flex-col items-center gap-y-6 text-center">
            <HiOutlineExclamationCircle className="text-7xl text-red-500" />
            <p className="text-xl text-gray-500">
              Are you sure you want to delete this athlete?
            </p>
            <div className="flex items-center gap-x-3">
              <Button
                color="failure"
                disabled={deleting}
                onClick={async () => {
                  setDelete(true);

                  const delete_trainer = await deleteTrainer(props.trainer.id);

                  console.log("==== Delete Trainer ====");

                  console.log(delete_trainer);

                  await props.getTrainers();

                  toast.success(
                    `Trainer with email ${props.trainer.title} deleted`,
                  );

                  setDelete(false);
                  setOpen(false);
                }}
              >
                {deleting ? "Deleting..." : "Yes, I'm sure"}
              </Button>
              <Button color="gray" onClick={() => setOpen(false)}>
                No, cancel
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DeleteTrainerModal;
