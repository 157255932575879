/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable tailwindcss/migration-from-tailwind-2 */
/* eslint-disable tailwindcss/no-custom-classname */
/* eslint-disable tailwindcss/classnames-order */
import type { CustomFlowbiteTheme } from "flowbite-react";
import {
  Button,
  Label,
  Modal,
  Select,
  Spinner,
  TextInput,
  Textarea,
  Tooltip,
} from "flowbite-react";
import { useEffect, useRef, useState } from "react";
import { HiOutlinePencilAlt } from "react-icons/hi";
import { toast } from "react-toastify";
import { getUserDetails, updateUser } from "../../services/user";
import type { UserResponse } from "../../types/apiResponses";
import DeleteUserModal from "./DeleteUserModal";

export const blueThemeSpinner: CustomFlowbiteTheme["spinner"] = {
  color: {
    info: "fill-blue-700",
  },
};

export const ThemeTooltip: CustomFlowbiteTheme["tooltip"] = {
  target: "w-full",
};

const EditUserModal = function (props: any) {
  const [isOpen, setOpen] = useState(false);
  const [updating, setUpdate] = useState(false);
  const [touchedFields, setTouchedFields] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [userDetails, setUserDetails] = useState<UserResponse | null>(null);
  const [user, setUser]: any = useState({});
  const rootRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setUser({
      ...props.user,
      private_profile: props.user.is_private,
      dob: normalizeDate(props.user.birthdate ?? ""),
    });
  }, [props.user, isOpen]);

  useEffect(() => {
    fetchUserDetails(user.id);
  }, [user, isOpen]);

  const fetchUserDetails = async (id: string) => {
    if (isOpen) {
      const userData = await getUserDetails(id);
      setUserDetails(userData);
      setIsLoading(false);
    }
  };

  const handleChange = (e: any) => {
    const { name, value, type, checked } = e.target;

    const updatedValue = name === "dob" ? normalizeDate(value) : value;

    // Immediately update for checkbox to ensure UI reflects the change.
    if (type === "checkbox") {
      setUser((prevUser: any) => ({
        ...prevUser,
        [name]: checked,
      }));
    } else {
      setUser((prevUser: any) => ({
        ...prevUser,
        [name]: updatedValue,
      }));
    }

    if (!touchedFields.includes(name)) {
      setTouchedFields((prevFields) => [...prevFields, name]);
    }
  };

  const handleSubmit = async () => {
    setUpdate(true);

    const updatedFields: any = {};
    touchedFields.forEach((field) => {
      updatedFields[field] = user[field];
    });

    // Now, use updatedFields instead of the entire user object to update the user
    const update_user = await updateUser(updatedFields, props.user.id);

    if (update_user.data.error) {
      toast.error(update_user.data.message);
    } else {
      props.getUsers();
      setOpen(false);
      toast.success("User updated successfully!");
    }

    setUpdate(false);
  };

  const normalizeDate = (dateString?: string): string | undefined => {
    if (!dateString) return "";

    // Check if the date is in mm/dd/yyyy format
    const isMMDDYYYY = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/;
    if (isMMDDYYYY.test(dateString)) {
      const [month, day, year] = dateString.split("/");
      if (month && day && year) {
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    }

    // Check if the date is in yyyy-mm-dd format
    const isYYYYMMDD = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;
    if (isYYYYMMDD.test(dateString)) {
      return dateString;
    }

    // Handle other unexpected formats
    const parsedDate = new Date(dateString);
    if (!isNaN(parsedDate.getTime())) {
      return parsedDate.toISOString().split("T")[0];
    }

    return "";
  };

  return (
    <>
      <div ref={rootRef}>
        <Button color="primary" onClick={() => setOpen(true)}>
          <div className="flex items-center gap-x-2">
            <HiOutlinePencilAlt className="text-lg" />
            Edit user
          </div>
        </Button>
        <Modal
          onClose={() => {
            setOpen(false);
            setIsLoading(true);
            console.log("closing");
          }}
          show={isOpen}
          root={rootRef.current ?? undefined}
          size="3xl"
          position="top-center"
        >
          <Modal.Header className="border-b border-gray-200 !p-6 dark:border-gray-700">
            <strong>Edit user</strong>
          </Modal.Header>
          <Modal.Body>
            {isLoading ? (
              <div className="min-h-48 flex justify-center items-center ">
                <Spinner theme={blueThemeSpinner} color="info" size="xl" />
              </div>
            ) : (
              <div className="grid grid-cols-1 gap-4 overflow-hidden sm:grid-cols-2">
                <div>
                  <Label htmlFor="firstName">Name</Label>
                  <div className="mt-1">
                    <TextInput
                      id={`name-${props.user.id}`}
                      name="name"
                      value={user.name || ""}
                      placeholder="Name"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div>
                  <Label htmlFor="lastName">Bio</Label>
                  <div className="mt-1">
                    <Textarea
                      id={`bio-${props.user.id}`}
                      name="bio"
                      placeholder="Bio"
                      value={user.bio || ""}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div>
                  <Label htmlFor="phone">Location</Label>
                  <div className="mt-1">
                    <TextInput
                      id={`location-${props.user.id}`}
                      name="location"
                      placeholder="Location"
                      type="text"
                      value={user.location || ""}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div>
                  <Label>Gender</Label>
                  <div className="mt-1">
                    <TextInput
                      id={`Gender-${props.user.id}`}
                      name="gender"
                      placeholder="Gender"
                      value={user.gender || ""}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div>
                  <Label>Weight</Label>
                  <div className="mt-1">
                    <TextInput
                      id={`weight-${props.user.id}`}
                      name="weight"
                      placeholder="Weight"
                      value={user.weight || ""}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div>
                  <Label>Height</Label>
                  <div className="mt-1">
                    <TextInput
                      id={`height-${props.user.id}`}
                      name="height"
                      placeholder="Height"
                      value={user.height || ""}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div>
                  <Label>Date of Birth</Label>
                  <div className="mt-1">
                    <input
                      type="date"
                      id={`dob-${props.user.id}`}
                      name="dob"
                      value={user.dob || ""}
                      onChange={handleChange}
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    />
                  </div>
                </div>
                <div>
                  <Label>Phone</Label>
                  <div className="mt-1">
                    <TextInput
                      id={`phone-${props.user.id}`}
                      name="phone"
                      placeholder="Phone"
                      value={user.phone || ""}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div>
                  <Label>Activity</Label>
                  <div className="mt-1">
                    <TextInput
                      id={`activity-${props.user.id}`}
                      name="activity"
                      placeholder="Activity"
                      value={user.activity || ""}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div>
                  <Label>Username</Label>
                  <div className="mt-1">
                    <TextInput
                      id={`username-${props.user.id}`}
                      name="username"
                      placeholder="Username"
                      value={user.username || ""}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div>
                  <Label htmlFor="status">Status</Label>
                  <Select
                    id="status"
                    name="status"
                    value={user.status}
                    onChange={handleChange}
                    className="mt-1"
                  >
                    <option value="STATUS_ACTIVE">Active</option>
                    <option value="STATUS_INACTIVE">Inactive</option>
                  </Select>
                </div>

                <div>
                  <Label htmlFor="status">Migration Status</Label>
                  <div className="mt-1">
                    <Tooltip
                      theme={ThemeTooltip}
                      content="Whether or not the user has been migrated to new Cognito User Pool."
                    >
                      <TextInput
                        placeholder={
                          userDetails?.userCognito?.needsMigration === true
                            ? "Needs Migration"
                            : "Does not need migration"
                        }
                        disabled
                      />
                    </Tooltip>
                  </div>
                </div>

                <div>
                  <Label htmlFor="status">Additional options</Label>
                  <div className="mt-3">
                    <label className="relative inline-flex cursor-pointer items-center">
                      <input
                        id="private_profile"
                        name="private_profile"
                        type="checkbox"
                        className="peer sr-only"
                        checked={user.private_profile || false}
                        onChange={handleChange}
                      />
                      <div className="peer h-6 w-11 rounded-full bg-gray-200 after:absolute after:top-[2px] after:left-[2px] after:size-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-blue-800"></div>
                      <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                        Private Profile
                      </span>
                    </label>
                  </div>
                </div>

                <div>
                  <Label htmlFor="status">Actions</Label>
                  <div className="mt-2">
                    <DeleteUserModal user={user} getUsers={props.getUsers} />
                  </div>
                </div>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              color="primary"
              disabled={updating || touchedFields.length === 0}
              onClick={handleSubmit}
            >
              {updating ? "Updating..." : "Update"}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default EditUserModal;
